import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { doc, Firestore, setDoc, Timestamp, collection, CollectionReference, DocumentData } from '@angular/fire/firestore';
import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CAREERS_COLLECTION, JOB_REQUESTS_COLLECTION } from 'src/app/constants';

@Component({
  selector: 'app-apply-job-dialog',
  templateUrl: './apply-job-dialog.component.html',
  styleUrls: ['./apply-job-dialog.component.scss']
})
export class ApplyJobDialogComponent implements OnInit {

  loader: boolean = false;

  @Input() careerModel: any;
  careerForm: FormGroup = new FormGroup({});
  tempImageFile: any = null;

  collectionRef!: CollectionReference<DocumentData>

  constructor(
    private fb: FormBuilder,
    private firestore: Firestore,
    private storage: Storage,
    public modal: NgbActiveModal,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.collectionRef = collection(this.firestore, CAREERS_COLLECTION, this.careerModel.careerId, JOB_REQUESTS_COLLECTION);
    this.initialisedCareerFG();
  }

  initialisedCareerFG() {
    this.careerForm = this.fb.group({
      jobRequestId: [doc(this.collectionRef).id],
      careerId: [this.careerModel.careerId],
      careerName: [this.careerModel.title],
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.minLength(10)]],
      message: [null],
      resumeUrl: [null],
      gender: [null],
      preferedLocation: [null, Validators.required],
      createDate: [Timestamp.now()],
    });
  }

  checkFileType(event: any) {
    this.tempImageFile = event.target.files[0];
    if (
      this.tempImageFile.type == "image/png" || 
      this.tempImageFile.type == "image/jpeg" || 
      this.tempImageFile.type == "image/jpg" || 
      this.tempImageFile.type == "application/pdf"
    ) {
      // console.log("File Ok");
    } else {
      // console.log("File not Ok");
      this.tempImageFile = null;
      this.toast.warning("Only .png/.jpeg/.jpg file format accepted!!", "");
    }
  } 

  async saveUserApplication() {
    if (this.careerForm.invalid) {
      this.careerForm.markAllAsTouched()
      return;
    }

    let values = { ...this.careerForm.value };
    this.loader = true;

    if(this.tempImageFile == null) {
      this.loader = false;
      this.toast.warning("Please Upload your resume", "Warning");
      return;
    } else {
      const FILE_PATH = `requests/${formatDate(new Date(), 'yyyyMMddHHmmss','en-US')}-${String(this.tempImageFile.name).replace(/ /g, '')}`;
      const FILE_REF = ref(this.storage, FILE_PATH);
      await uploadBytes(FILE_REF, this.tempImageFile);
      values.resumeUrl = await getDownloadURL(FILE_REF);
    }

    let docRef = doc(this.collectionRef, values.jobRequestId);
    setDoc(docRef, { ...values }, { merge: true })
      .then(() => {
        this.loader = false;
        this.toast.success("Application Submitted", "Success");
        this.modal.close();
      }, (error) => {
        this.loader = false
        this.toast.error("Something Went Wrong", "Failed");
      })
  }
}
