<div class="offcanvas-header border-0 sticky-top bg-white">
  <h4 class="offcanvas-title" id="offcanvas-basic-title">Sethi Study Circle</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
</div>
<div class="offcanvas-body">
  <ul class="list-group list-group-flush">
    <li class="list-group-item px-0" routerLink="/">
      <span class="fw-bold">Home</span>
    </li>
    <li class="list-group-item px-0" routerLink="/about-us">
      <span class="fw-bold">About Us</span>
    </li>
    <li class="list-group-item px-0">
      <div class="d-flex justify-content-between align-items-center" (click)="isServiceCollapse = !isServiceCollapse">
        <span class="fw-bold">Services</span>
        <span class="bi {{ isServiceCollapse ? 'bi-chevron-right' : 'bi-chevron-down' }}"></span>
      </div>
      <div class="collapse" [ngbCollapse]="isServiceCollapse">
        <ul class="list-group list-group-flush">
          <li class="list-group-item border-0 py-2" *ngFor="let service of dbService.servicesModelSubject | async" [routerLink]="['service', service.serviceUrlId]">
            <a class="nav-link" aria-label="servicetitle" >{{ service.title }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="list-group-item px-0">
      <div class="d-flex justify-content-between align-items-center" (click)="isCourseCollapse = !isCourseCollapse">
        <span class="fw-bold">Courses</span>
        <span class="bi {{ isCourseCollapse ? 'bi-chevron-right' : 'bi-chevron-down' }}"></span>
      </div>
      <div class="collapse" [ngbCollapse]="isCourseCollapse">
        <ul class="list-group list-group-flush">
          <li class="list-group-item border-0 py-2" *ngFor="let course of dbService.coursesModelSubject | async" [routerLink]="['course', course.courseUrlId]">
            <a class="nav-link" aria-label="coursetitle">{{ course.title }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="list-group-item px-0">
      <div class="d-flex justify-content-between align-items-center" (click)="isBranchCollapse = !isBranchCollapse">
        <span class="fw-bold">Branches</span>
        <span class="bi {{ isBranchCollapse ? 'bi-chevron-right' : 'bi-chevron-down' }}"></span>
      </div>
      <div class="collapse" [ngbCollapse]="isBranchCollapse">
        <ul class="list-group list-group-flush">
          <li class="list-group-item border-0 py-2" *ngFor="let branch of dbService.branchesModelSubject | async" [routerLink]="['branch', branch.branchUrlId]">
            <a class="nav-link" aria-label="branchtitle">
              {{ branch.title }}
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="list-group-item px-0" routerLink="/contact-us">
      <span class="fw-bold">Contact Us</span>
    </li>
    <li class="list-group-item px-0" routerLink="/news">
      <span class="fw-bold">News</span>
    </li>
    <li class="list-group-item px-0" routerLink="/events">
      <span class="fw-bold">Events</span>
    </li>
    <li class="list-group-item px-0">
      <span class="fw-bold">Blogs</span>
    </li>
    <li class="list-group-item px-0" routerLink="/frequently-asked-questions">
      <span class="fw-bold">Frequenty Asked Questions</span>
    </li>
    <li class="list-group-item px-0">
      <div class="d-flex justify-content-between align-items-center" (click)="isExamTopicCollapse = !isExamTopicCollapse">
        <span class="fw-bold">Exam Topics</span>
        <span class="bi {{ isExamTopicCollapse ? 'bi-chevron-right' : 'bi-chevron-down' }}"></span>
      </div>
      <div class="collapse" [ngbCollapse]="isExamTopicCollapse">
        <ul class="list-group list-group-flush">
          <!-- <li class="list-group-item border-0 py-2" routerLink="/cue-card-topics">
            <a class="nav-link">
              Cue Card
            </a>
          </li> -->
          <li class="list-group-item border-0 py-2" routerLink="/writing-task2-topics">
            <a class="nav-link" aria-label="writingtask">
              Writing Task 2
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="list-group-item px-0" *ngIf="(dbService.brochuresModelSubject | async)?.length !== 0">
      <div class="d-flex justify-content-between align-items-center special" (click)="isBrochureCollapse = !isBrochureCollapse">
        <span class="fw-bold">
          <i class="bi bi-star"></i>
          Brochures
        </span>
        <span class="bi {{ isBrochureCollapse ? 'bi-chevron-right' : 'bi-chevron-down' }}"></span>
      </div>
      <div class="collapse" [ngbCollapse]="isBrochureCollapse">
        <ul class="list-group list-group-flush">
          <li class="list-group-item border-0 py-2" *ngFor="let branch of dbService.brochuresModelSubject | async">
            <a [href]="branch.imageUrl" class="nav-link" target="_blank" aria-label="branch title">
              {{ branch.title }}
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="list-group-item px-0 special" routerLink="/calculate-ielts">
      <span class="fw-bold">
        <i class="bi bi-star"></i>
        Calculate IELTS Score
      </span>
    </li>
    <li class="list-group-item px-0 special" routerLink="/jobs">
      <span class="fw-bold">
        <i class="bi bi-star"></i>
        Job Vacancies
      </span>
    </li>
    <li class="list-group-item px-0 special" (click)="openEnquiryModal()">
      <span class="fw-bold">
        <i class="bi bi-star"></i>
        Enquire Now
      </span>
    </li>
  </ul>
</div>