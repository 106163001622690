<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <div style="
        background: url('./../../../assets/heads/bannerImage1.webp') no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 280px;
      ">
      </div>
      <!-- <img loading="lazy" src="./../../../assets/bannerImage.jpg" alt="" class="img-fluid"
        style="height: 400px; width: 100%; object-fit: cover; border-radius: 0;">
    </div> -->
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-self-center">
        <h2 >
          About Us
        </h2>
        <p class="text-justify">
          SSC is a pioneer organization of learning which was established in
          1980 under the vision of Professor Pritpal Singh Sethi. It started
          with one lecture hall setup for Spoken English and today it is a
          well-known brand in Punjab with Ten state of the art branches in
          Ludhiana, Mullanpur, Jagraon , Dehlon, Ahmedgarh & Malerkotla. After a
          few years of inception, the centre added a specialized coaching in
          Personality Development, which till date remains in high demand
          amongst all age groups.
        </p>
        <p class="text-justify">
          This process of innovation and creative
          learning became the foundation of the organization. Keeping in mind
          the burgeoning demand for courses in Spoken English, SSC has designed
          unique course content to train and equip even a fresher in the art of
          Spoken English with forceful thrust of Personality Development
          training. 
        </p>
        <p class="text-justify">
          In 1995, SSC launched the IELTS training program along with
          the Spoken English and Personality Development programs. Introduction
          of IELTS training was a huge success for the organization and the
          demand for IELTS saw a steep growth in the following years. SSC, as an
          organization, has always believed in quality training with the use of
          latest techniques. The programming of the organization has been coined
          out of western methods of education wonderfully blended with Indian
          psycho-systems.
        </p>
      </div>
    </div>
  </div>
</div>
