<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <div style="
        background: url('./../../../assets/heads/faqs.jpg') no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 250px;
      ">
      </div>
      <!-- <img loading="lazy" src="./../../../assets/images/banner9.jpg" alt="" class="img-responsive"
        style="height: 250px; width: 100%; object-fit: fill; border-radius: 0;"> -->
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-self-center">
        <h2 class="mb-4">
          Frequently Asked Questions
        </h2>
        <ngb-accordion #accordion="ngbAccordion" activeIds="faq-0" [closeOthers]="true">
          <ngb-panel *ngFor="let faq of faqList; index as idx" [id]="'faq'+idx">
            <ng-template ngbPanelTitle let-opened="opened">
              <h5 class="m-0 text-wrap w-75 ssc-text-color">{{ faq.question }}</h5>
            </ng-template>
            <ng-template ngbPanelContent >
              <span class="text-muted">{{ faq.description }}</span>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>
