import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DbService } from 'src/app/services/db.service';
import { EnquiryDialogComponent } from '../enquiry-dialog/enquiry-dialog.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  isBranchCollapse: boolean = true;
  isServiceCollapse: boolean = true;
  isCourseCollapse: boolean = true;
  isBrochureCollapse: boolean = true;
  isExamTopicCollapse: boolean = true;

  constructor(
    public offcanvas: NgbOffcanvas,
    private modal: NgbModal,
    public dbService: DbService
  ) {}


  openEnquiryModal() {
    this.offcanvas.dismiss('navitem-clicked');
    let modalRef = this.modal.open(EnquiryDialogComponent);
    modalRef.componentInstance.inModalBool = true;
  }
}
