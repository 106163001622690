import { Component, Input, OnInit } from '@angular/core';
import { collection, doc, Firestore, Timestamp } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { setDoc } from '@firebase/firestore';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-enquiry-dialog',
  templateUrl: './enquiry-dialog.component.html',
  styleUrls: ['./enquiry-dialog.component.scss']
})
export class EnquiryDialogComponent implements OnInit {

  @Input() inModalBool: boolean = false;
  @Input() showRequestCallbackBool: boolean = true;
  enquiryForm: FormGroup = new FormGroup({});

  courses: string[] = [
    "IELTS",
    "PTE",
    "Spoken English",
  ];
  services: string[] = [
    "Study Visa",
    "Visitor Visa",
    "LMIA",
    "ECA",
    "Permanent Residency"
  ];

  selectedServices: string[] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NgbModal,
    private firestore: Firestore,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.initEnquiryForm();
  }

  checkedSelectedServices(value: string):boolean {
    return this.selectedServices.some(x => x === value);
  }

  updateSelectedServices(event: any, value: string) {
    let checked: boolean = event.target.checked;
    if(checked) {
      this.selectedServices.push(value)
    } else {
      let idx = this.selectedServices.findIndex(x => x === value);
      this.selectedServices.splice(idx, 1);
    }    
  }

  initEnquiryForm() {
    this.selectedServices = ["IELTS"];
    this.enquiryForm = this.fb.group({
      appointmentId: [doc(collection(this.firestore, 'enquries')).id],
      name: ["", [Validators.required, Validators.pattern("[a-zA-Z ]*")]],
      phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("[0-9]{10}$")]],
      email: ["", [Validators.required, Validators.email]],
      timestamp: [Timestamp.now()],
      services: ["IELTS"],
      message: [""],
      requestCallback: [true],
    });
  }

  async onSubmitEnquiry() {
    if(this.enquiryForm.invalid) {
      this.enquiryForm.markAllAsTouched();
      return;
    }

    if(this.selectedServices.length === 0) {
      this.toast.warning("Please select atleat 1 service", "")
      return;
    }
    let values = { ...this.enquiryForm.value };
    values.services = [...this.selectedServices];

    let docRef = doc(this.firestore, `enquries/${values.appointmentId}`);
    setDoc(docRef, { ...values }, { merge: true})
      .then(() => {
        if(this.inModalBool) this.modal.dismissAll();
        this.toast.success("Enquiry Submitted", "Success");
        this.initEnquiryForm();
      }, (error) => {
        if(this.inModalBool) this.modal.dismissAll();
        this.toast.error("Something went wrong", "Failed")
        return true;
      });
  }
}
