<div class="modal-header border-0">
  <!-- <h4 class="modal-title">Send Enquiry</h4> -->
  <button type="button" class="btn-close float-end" aria-label="Close" id="closeAddNewStaffModal" #closeAddNewStaffModal
    (click)="modal.dismiss('Cross click')">
    <!-- <span aria-hidden="true">&times;</span> -->
  </button>
</div>
<img loading="lazy" [src]="popupModel.popUpImageUrl" alt="popup" class="img-fluid mb-2" height="100px" *ngIf="popupModel.popUpImageUrl !== ''"
  loading="lazy">
<div class="modal-body" style="gap: 12px;">
  <h4>{{ popupModel.popupTitle }}</h4>
  <p>{{ popupModel.popUpDescription }}</p>
  <div class="d-flex flex-column flex-sm-row align-items-stretch mt-4" style="gap: 12px;">
    <a target="_blank" [href]="(dbService.socialSubject | async)?.facebook" class="btn btn-md w-100 rounded custom-outline-btn" aria-label="facebook icon">
      <i class="bi bi-facebook mr-1"></i>
      Facebook
    </a>
    <a target="_blank" [href]="'tel:+91'+(dbService.mainBranch | async)?.phone" class="btn btn-md w-100 rounded custom-outline-btn" aria-label="call icon">
      <i class="bi bi-telephone mr-1"></i>
      Call Us
    </a>
    <a target="_blank" [href]="(dbService.socialSubject | async)?.instagram" class="btn btn-md w-100 rounded custom-outline-btn" aria-label="instragram icon">
      <i class="bi bi-instagram mr-1"></i>
      Instagram
    </a>
  </div>
</div>