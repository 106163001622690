<div class="container my-5">
  <div class="row mx-lg-4 pt-4 pb-2">
    <div class="col">
      <h2 class="text-center display-4 heading-font">Gallery</h2>
    </div>
  </div>
  <div class="row mx-lg-4 py-2">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let gallery of galleryList; index as idx">
      <a [href]="gallery.imageUrl" target="_blank" aria-label="gallery">
        <img loading="eager" [src]="gallery.imageUrl" alt="gallery" class="img-fluid rounded">
      </a>
    </div>
  </div>
</div>