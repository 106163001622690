import { Component, OnInit } from '@angular/core';
import { DbService } from './services/db.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ssc_website';
  social: any = {};

  loader: boolean = true;

  constructor(
    private dbService: DbService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.loader = false;
      this.getSoialUrl();
    }, 100);
  }

  getSoialUrl() {
    let unsub = this.dbService.socialSubject.subscribe((value) => {
      if(value !== null) {
        this.social = { ...value };
        this.dbService.getWindowRef().setTimeout(() => unsub.unsubscribe(), this.dbService.timeoutInterval * 6)
      }
    })
  }
}
