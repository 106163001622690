<app-loader *ngIf="loader"></app-loader>
<div *ngIf="!loader">
  <app-navbar></app-navbar>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

<div *ngIf="!loader" style="position: fixed; left: 10px; bottom: 10px; z-index: 1;">
  <div class="d-flex flex-row flex-lg-column  rounded bg-white">
    <a href="{{ social?.facebook }}" target="_blank" class="btn mx-1 " aria-label="facebook icon">
      <i class="fa-brands fa-facebook fa-lg"></i>
    </a>
    <a href="{{ social?.youtube }}" target="_blank" class="btn  mx-1 " aria-label="youtube icon">
      <i class="fa-brands fa-youtube fa-lg"></i>
    </a>
    <a href="{{ social?.instagram }}" target="_blank" class="btn mx-1 " aria-label="instragram icon" >
      <i class="fa-brands fa-instagram fa-lg"></i>
    </a>
    <a href="{{ social?.whatsappNumber }}" target="_blank" class="btn mx-1" aria-label="whatsapp icon">
      <i class="fa-brands fa-whatsapp text-success fa-lg"></i>
    </a>
    <!-- <a type="button" class="btn rounded-pill mx-2 font-weight-bold d-block d-md-none" style="background: #f18067; color: #fff;"
      (click)="openEnquireModal(enquireModalRef)">
      <i class="fa-solid fa-question-circle fa-lg mr-1"></i>
      Enquire Now
    </a> -->
  </div>
</div>