import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-services',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServicesComponent implements OnInit {

  serviceUrlId!: string;
  safeServiceContent!: SafeHtml;
  serviceModel: any = {};

  constructor(
    private route: ActivatedRoute,
    private dbService: DbService
  ) {
    route.params.subscribe(({ serviceId }) => {
      this.serviceUrlId = serviceId ?? null;
      this.getData();
    })
  }

  ngOnInit(): void {
    
  }

  getData() {
    let branchModelSub = this.dbService.servicesModelSubject.subscribe((list) => {
      if(list !== null) {
        this.serviceModel = list.find((x: any) => x['serviceUrlId'] === this.serviceUrlId);
        this.safeServiceContent = this.dbService.sanitizer.bypassSecurityTrustHtml(this.serviceModel?.description ?? "")
        this.dbService.getWindowRef().setTimeout(() => branchModelSub.unsubscribe(), this.dbService.timeoutInterval)
      }
    })
  }

}
