import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sliderImages: any[] = [
    { imageUrl: './../../../assets/static/sliders/sliderr1.webp', title: 'SSC Banner Image'},
    // { imageUrl: './../../../assets/static/sliders/slider2.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/sliders/sliderr3.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/sliders/sliderr4.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/sliders/sliderr5.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/sliders/sliderr6.webp', title: 'SSC Banner Image' }
  ];

  galleryList: any[] = [
    { imageUrl: './../../../assets/static/gallery1/gallery1.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/gallery1/gallery2.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/gallery1/gallery3.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/gallery1/gallery4.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/gallery1/gallery5.webp', title: 'SSC Banner Image' },
    { imageUrl: './../../../assets/static/gallery1/gallery6.webp', title: 'SSC Banner Image' }
  ];
  reviewsList: any[] = [];
  albumsList: any[] = [];
  videosList: any[] = [];

  quoteModel: any = {
    quote: "Best Education and Immigration Here !",
    author: "Sethi Study Circle"
  };

  constructor(
    public dbService: DbService,
    private metaTagService: Meta
  ) {}

  ngOnInit(): void {
    this.getDataFromDbService();
    this.metaTagService.addTags([
      {
        name: "keywords",
        content: "Sethi Study Circle",
      },
      { name: "robots", content: "index, follow" },
      { name: "title", content: "Sethi Study Circle - Home" },
      { name: "author", content: "Sethi Study Circle" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "date", content: "2020-09-22", scheme: "YYYY-MM-DD" },
      { charset: "UTF-8" },
    ]);
  }

  getDataFromDbService() {
    this.dbService.getQuote();
    let quoteSub = this.dbService.quoteSubject.subscribe((value) => {
      if(value !== null) {
        this.quoteModel = { ...value };
        this.dbService.getWindowRef().setTimeout(() => quoteSub.unsubscribe(), this.dbService.timeoutInterval * 60)
      }
    })

    // let sub = this.dbService.slidersSubject.subscribe((value) => {
    //   if(value.length !== 0) {
    //     this.sliderImages = this.sliderImages.concat(value);
    //     this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 60)
    //   }
    // })

    // let gallerySub = this.dbService.homeGallerySubject.subscribe((value) => {
    //   if(value.length !== 0) {
    //     this.galleryList = value;
    //     this.dbService.getWindowRef().setTimeout(() => gallerySub.unsubscribe(), this.dbService.timeoutInterval * 60)
    //   }
    // })

    this.dbService.getHomeReviews();
    let reviewSub = this.dbService.homeReviewSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.reviewsList = value;
        this.dbService.getWindowRef().setTimeout(() => reviewSub.unsubscribe(), this.dbService.timeoutInterval * 60)
      }
    })

    let albumSub = this.dbService.albumsSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.albumsList = value;
        this.dbService.getWindowRef().setTimeout(() => albumSub.unsubscribe(), this.dbService.timeoutInterval * 60)
      }
    })

    this.dbService.getHomeVideos();
    let videoSub = this.dbService.homeVideosSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.videosList = value;
        this.dbService.getWindowRef().setTimeout(() => videoSub.unsubscribe(), this.dbService.timeoutInterval * 60)
      }
    })
  }

}
