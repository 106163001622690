import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-subblogs',
  templateUrl: './subblogs.component.html',
  styleUrls: ['./subblogs.component.scss']
})
export class SubblogsComponent implements OnInit {
  blogs:any = {};

  constructor(
    private dbService: DbService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(({ blogId }: any) => {
      this.getBlogModelFromDb(blogId);
    })
  }

  ngOnInit(): void {
    
  }

  getBlogModelFromDb(blogId: string) {
    if(this.dbService.blogsSubject.value.length === 0) this.dbService.getBlogs();
    let sub = this.dbService.blogsSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.blogs = value.find(x => x.blogUrlId === blogId);
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6)
      }
    })
  }
}
