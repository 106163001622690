import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  newsList: any[] = [];
  constructor(
    private dbService: DbService
  ) {}

  ngOnInit(): void {
    if(this.dbService.newsSubject.value.length === 0) this.dbService.getNews();
    let sub = this.dbService.newsSubject.subscribe((list) => {
      if(list.length !== 0) {
        this.newsList = [...list];
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6)
      }
    });
  }
}
