import { Component } from '@angular/core';
import { BlogModel } from "src/app/modals/BlogsModel";
import { DbService } from "src/app/services/db.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent {

  blogModelList: any[] = [];

  constructor(
    private dbService: DbService
  ) {}

  ngOnInit(): void {
    if(this.dbService.blogsSubject.value.length === 0) this.dbService.getBlogs();
    let sub = this.dbService.blogsSubject.subscribe((list) => {
      if(list.length !== 0) {
        this.blogModelList = [...list];
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6)
      }
    });
  }

}
