<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <div style="
        background: url('./../../../assets/heads/courses1.webp') no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 250px;
      ">
      </div>
    </div>
  </div>
</div>

<div class="container my-5">
  <div class="row">
    <div class="col" >
      <div style="height: 100px; gap: 8px;" class="d-flex align-items-center rounded shadow-sm p-3"
        [ngStyle]="{
          'background': courseModel?.bannerBgColor || '#eee'
        }">
        <i class="bi bi-book fs-2"></i>
        <h2 class="m-0">{{ courseModel?.title || "" }}</h2>
      </div>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-self-center">
        <div [innerHTML]="safeCourseContent" class="text-justify"></div>
      </div>
    </div>
  </div>
  <!-- <hr class="my-4">
  <div class="row">
    <div class="col-12">
      <h4>Batches</h4>
    </div>
  </div>
  <div class="row mt-2" *ngIf="batches.length !== 0">
    <div class="col-12 col-sm-4 " *ngFor="let batch of batches; index as idx">
      <div class="d-flex flex-column border border-dark rounded p-3">
        <h5 class="">{{ batch.title }}</h5>
      <span>{{ batch.timestamp.toDate() | date: 'dd-MM-yyyy hh:mm:ss a' }}</span>
      </div>
    </div>
  </div>
  <div class="row mt-2" *ngIf="batches.length === 0">
    <div class="col-12 mt-2">
      <h6 class="text-muted">No Batches till now</h6>
    </div>
  </div> -->


</div>