import { Component } from '@angular/core';

@Component({
  selector: 'app-calculate-ielts',
  templateUrl: './calculate-ielts.component.html',
  styleUrls: ['./calculate-ielts.component.scss']
})
export class CalculateIELTSComponent {
  nextStep: number = 0;

  listeningScore?: number;
  readingScore?: number;
  writtingBand?: number;
  speakingBand?: number;
  paperType?: number;

  calculatedBand?: string;

  resetField() {
    this.nextStep = 0
    delete this.listeningScore;
    delete this.writtingBand;
    delete this.readingScore;
    delete this.speakingBand;
    delete this.calculatedBand;
  }

  incrementStep() {
    if (this.nextStep !== 5) {
      this.nextStep++;
    }
  }

  decrementStep() {
    if (this.nextStep !== 0) {
      this.nextStep--;
    }
  }

  getListeningBand(score: number): number {
    if (score == 0) {
      return 0;
    }
    if (score >= 1 && score <= 2) {
      return 1;
    }
    if (score == 3) {
      return 2;
    }
    if (score >= 4 && score <= 5) {
      return 2.5;
    }
    if (score >= 6 && score <= 7) {
      return 3;
    }
    if (score >= 8 && score <= 10) {
      return 3.5;
    }
    if (score >= 11 && score <= 12) {
      return 4;
    }
    if (score >= 13 && score <= 15) {
      return 4.5;
    }
    if (score >= 16 && score <= 17) {
      return 5;
    }
    if (score >= 18 && score <= 22) {
      return 5.5;
    }
    if (score >= 23 && score <= 25) {
      return 6;
    }
    if (score >= 26 && score <= 29) {
      return 6.5;
    }
    if (score >= 30 && score <= 31) {
      return 7;
    }
    if (score >= 32 && score <= 34) {
      return 7.5;
    }
    if (score >= 35 && score <= 36) {
      return 8;
    }
    if (score >= 37 && score <= 38) {
      return 8.5;
    }
    if (score >= 39 && score <= 40) {
      return 9;
    }

    return -1;
  }

  getReadingAcadmicBand(score: number): number {
    if (score == 0) {
      return 0;
    }
    if (score >= 1 && score <= 2) {
      return 1;
    }
    if (score == 3) {
      return 2;
    }
    if (score >= 4 && score <= 5) {
      return 2.5;
    }
    if (score >= 6 && score <= 7) {
      return 3;
    }
    if (score >= 8 && score <= 9) {
      return 3.5;
    }
    if (score >= 10 && score <= 12) {
      return 4;
    }
    if (score >= 13 && score <= 14) {
      return 4.5;
    }
    if (score >= 15 && score <= 18) {
      return 5;
    }
    if (score >= 19 && score <= 22) {
      return 5.5;
    }
    if (score >= 23 && score <= 26) {
      return 6;
    }
    if (score >= 27 && score <= 29) {
      return 6.5;
    }
    if (score >= 30 && score <= 32) {
      return 7;
    }
    if (score >= 33 && score <= 34) {
      return 7.5;
    }
    if (score >= 35 && score <= 36) {
      return 8;
    }
    if (score >= 37 && score <= 38) {
      return 8.5;
    }
    if (score >= 39 && score <= 40) {
      return 9;
    }

    return -1;
  }
  
  getGeneralBand(score: number): number {
    if (score == 0) {
      return 0;
    }
    if (score == 1) {
      return 1;
    }
    if (score >= 2 && score <= 4) {
      return 2;
    }
    if (score == 5) {
      return 2.5;
    }
    if (score >= 6 && score <= 8) {
      return 2.5;
    }
    if (score >= 9 && score <= 11) {
      return 3;
    }
    if (score >= 12 && score <= 14) {
      return 3.5;
    }
    if (score >= 15 && score <= 18) {
      return 4;
    }
    if (score >= 19 && score <= 22) {
      return 4.5;
    }
    if (score >= 23 && score <= 26) {
      return 5;
    }
    if (score >= 27 && score <= 29) {
      return 5.5;
    }
    if (score >= 30 && score <= 31) {
      return 6;
    }
    if (score >= 32 && score <= 33) {
      return 6.5;
    }
    if (score >= 34 && score <= 35) {
      return 7;
    }
    if (score == 36) {
      return 7.5;
    }
    if (score >= 37 && score <= 38) {
      return 8;
    }
    if (score == 39) {
      return 8.5;
    }
    if (score == 40) {
      return 9;
    }

    return -1;
  }

  calcualteScore() {
    this.nextStep++;
    let listeningBand = this.getListeningBand(this.listeningScore!);
    let readingBand = this.paperType
      ? this.getGeneralBand(this.readingScore!)
      : this.getReadingAcadmicBand(this.readingScore!);
    let avg =
      (listeningBand! + readingBand! + this.writtingBand! + this.speakingBand!) / 4;
    this.calculatedBand = (Math.round(avg * 2) / 2).toFixed(1);
    console.log(this.calculatedBand);
  }
}
