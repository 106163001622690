export const COURSES_COLLECTION = 'courses';
export const SERVICES_COLLECTION = 'services';
export const BROCHURES_COLLECTION = 'brochures';
export const CONTACT_COLLECTION = 'Contactus-Details';
export const FAQ_COLLECTION = "faq";
export const CAREERS_COLLECTION = "careers";
export const JOB_REQUESTS_COLLECTION = "job-requests";
export const TOPICS_COLLECTION = "topics";
export const ALBUMS_COLLECTION = "albums";
export const QUERIES_COLLECTION = "queries";
export const ALBUMS_MEDIA_COLLECTION = "album-media";
export const SOCIAL_COLLECTION ='socialLinks';
export const SLIDER_COLLECTION = 'sliders';
export const GALLERY_COLLECTION = 'Gallery';
export const REVIEWS_COLLECTION = 'Reviews';
export const VIDEOS_COLLECTION = 'videos';
export const BLOGS_COLLECTION = 'Blogs';
export const POPUP_COLLECTION = 'popup';
export const QUOTE_COLLECTION = 'quote';
export const NEWS_COLLECTION = 'news';
