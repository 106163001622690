<div class="container-fluid">
    <div class="row">
      <div class="col p-0">
        <div style="
          background: url('./../../../assets/heads/blogs1.webp') no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 250px;
        ">
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="row mx-lg-4">
      <div class="col">
        <div class="d-flex flex-column justify-content-center align-self-center">
          <h2> Our Blogs </h2>
        </div>
      </div>
    </div>
    <div class="row mx-lg-4 gap-4" style="margin: 1.5rem 0;">
      <div class="col-12" *ngFor="let ele of blogModelList; even as even; odd as odd;">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-4">
                <img loading="eager" [src]="ele?.imageUrl || './../../../assets/heads/bannerImage.jpg'" [alt]="ele?.newsTitle" class="img-fluid rounded" style="height: 200px; width: 100%; object-fit: fill; object-position: center;">
              </div>
              <div class="col-12 col-sm-8">
                <h4 class="card-title">{{ ele?.title || '' | titlecase }}</h4>
                <p class="card-text text-limit" [innerHTML]="ele?.description || ''"></p>
                <button type="button" class="btn btn-outline-primary rounded-pill" aria-label="read" [routerLink]="[ele.blogUrlId]">Read More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  