<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <div style="
        background: url('./../../../assets/heads/career1.webp') no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 250px;
      ">
      </div>
    </div>
  </div>
</div>

<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-self-center">
        <h2>Job Vacancies</h2>
        <p class="text-muted mb-4">Join Sethi Study Circle Team</p>
        <ngb-accordion #accordion="ngbAccordion" [closeOthers]="true">
          <ngb-panel *ngFor="let career of careerModelList; index as idx" [id]="'carrer'+ idx">
            <ng-template ngbPanelTitle let-opened="opened">
              <div class="d-flex" style="gap: 8px; width: 100%; height: 100%;">
                <span class="fw-bold ssc-text-color ">{{ career.title }}</span> |
                <div [ngSwitch]="career.preferences" class="fw-bold text-dark">
                  <span *ngSwitchCase="0">Male</span>
                  <span *ngSwitchCase="1">Female</span>
                  <span *ngSwitchCase="2">Male or Female</span>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="d-flex flex-column align-items-start">
                <span>Locations: </span>
                <div class="d-flex flex-wrap gap-1 mt-2">
                  <span *ngFor="let loc of career.locations"
                  class="border rounded-pill py-1 px-2" style="font-size: 14px;">
                  {{ loc | titlecase }}
                </span>
                </div>
              </div>
              <div class="mt-3" [innerHTML]="career?.paraSafe"></div>
              <button type="button" class="btn custom-btn mt-4" aria-label="apply" (click)="openCareerModal(career)">
                Apply Now
                <i class="bi bi-chevron-double-right"></i>
              </button>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>