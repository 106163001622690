<div class="container-fluid mt-2 my-4">
  <div class="row mx-lg-4">
    <div class="col-12 col-lg-9">
      <ngb-carousel [interval]="3000" [keyboard]="false" [wrap]="true" [animation]="true" [showNavigationIndicators]="false">
        <ng-template ngbSlide *ngFor="let slide of sliderImages" class="rounded">
          <div class="picsum-img-wrapper rounded">
            <img [src]="slide.imageUrl" [alt]="slide.title" class="rounded  d-none d-lg-block" aria-label="sliderImages" loading="eager" style="height: 700px; width: 100%; object-fit: fill; object-position: center;" rel="preload" />
            <img [src]="slide.imageUrl" [alt]="slide.title" class="rounded  d-block d-lg-none" aria-label="sliderImages" loading="eager" style=" height: 250px; width: 100%; object-fit: fill; object-position: center;" rel="preload" />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="col-12 col-lg-3 mt-4 mt-lg-0">
      <div class="card border border-primary">
        <app-enquiry-dialog></app-enquiry-dialog>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container">
    <div class="row mx-lg-4">
      <div class="col" style="font-family: 'Times New Roman', Times, serif;">
        <figure class="text-center">
          <blockquote class="blockquote">
            <p class="fs-2">{{ quoteModel.quote }}</p>
          </blockquote>
          <figcaption class="blockquote-footer">
            <span class="fs-4">{{ quoteModel.author || "Sethi Study Circle" }}</span>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid my-5">
  <div class="row mx-lg-4">
    <div class="col-12 col-lg-9 d-flex flex-column order-1 order-sm-0 mt-3 mt-lg-0" style="gap: 8px;">
      <span class="h4 heading-font d-block d-lg-none">Why Choose Us</span>
      <span class="display-4 heading-font d-none d-lg-block">Why Choose Us</span>
      <span class="text-justify mt-3">
        SSC is a pioneer organization of learning which was established in
        1980 under the vision of Professor Pritpal Singh Sethi. &nbsp;SSC,
        as an organization, has always believed in quality training with the
        use of latest techniques.The programming of the organization has
        been coined out of western methods of education wonderfully blended
        with Indian psycho-systems.
      </span>
      <span class="text-justify mt-3">
        In 2007, SSC started a new wing, SSC Education Abroad, under the
        leadership of Kanwar Sethi, to assist students with overseas
        education. Based on the same principles as of the parent concern, the
        team of SSC Education Abroad showed tremendous enthusiasm and it paid
        off exceptionally well.
      </span>
      <span class="text-justify mt-3">
        Every passing year saw a tremendous growth in
        the number of successful visas for SSC students for countries like
        USA, Canada, New Zealand, Australia and UK. Hence, SSC provides an
        in-house facility for students with study visa counselling and IELTS
        preparation under one roof. It is our constant endeavour to improve as
        an organization and bring a positive change to more lives.
      </span>
    </div>
    <div class="col-12 col-lg-3 order-0 order-sm-1">
      <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
        <img loading="eager" src="./../../../assets/static/celebration1.webp" class="rounded-4 d-none d-lg-block" alt="Chooseus" style="height:48vh ; width:24vw ;">
        <img loading="eager" src="./../../../assets/static/celebration1.webp" class="rounded-4 d-md-block d-none d-lg-none" alt="Chooseus" style="height:48vh ; width:40vw ;">
        <img loading="eager" src="./../../../assets/static/celebration1.webp" class="rounded-4  d-xs-block d-md-none" alt="Chooseus" style="height:48vh ; width:85vw ;">
    </div>
  </div>
</div>

<!-- <div class="container my-5">
  <div class="row mx-lg-4 pt-4 pb-2">
    <div class="col">
      <h2 class="text-center display-4 heading-font">Events</h2>
    </div>
  </div>
  <div class="row mx-lg-4 pt-2 pb-4">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let gallery of albumsList; index as idx">
      <div class="card">
        <div class="card-body bg-none">
          <div class="row">
            <div class="col">
              <img loading="lazy" [src]="gallery.imageUrl" alt="" class="card-img-top rounded" loading="lazy">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <h5 class="text-wrap">{{ gallery.title | titlecase }}</h5>
              <p class="text-muted paraLimit">{{ gallery.description }}</p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col">
              <button type="button" class="btn btn-sm btn-outline-primary float-right" [routerLink]="['/events', gallery.albumId]">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid  my-5">
  <div class="container">
    <div class="row mx-lg-4 pt-4 pb-2">
      <div class="col">
        <h2 class="text-center display-4 heading-font">Gallery</h2>
      </div>
    </div>
    <div class="row mx-lg-4 py-2">
      <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let gallery of galleryList; index as idx">
        <a [href]="gallery.imageUrl" target="_blank" aria-label="gallery1">
          <img loading="eager" [src]="gallery.imageUrl" alt="gallery1" class="img-fluid rounded" style="height:370px; width:100%" >
        </a>
      </div>
    </div>
    <div class="row mx-lg-4 pb-4" *ngIf="dbService.getImageBool">
      <div class="col d-flex justify-content-center">
        <button type="button" class="btn btn-lg btn-outline-primary" aria-label="explore" routerLink="/images">
          Explore More
          <span class="bi bi-chevron-double-right"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container my-5">
  <div class="row mx-lg-4 pt-4 pb-2">
    <div class="col">
      <h2 class="text-center display-4 heading-font">Student Reviews</h2>
    </div>
  </div>
  <div class="row mx-lg-4 py-2">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let review of reviewsList; index as idx">
      <div class="card" style="height: 100%;">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center" style="height: 100%;">
            <img loading="eager" [src]="review.imageUrl || '../../../assets/static/empty-profile.png'" alt="review1" class="img-fluid rounded-3" width="100" height="100" >
            <h3 class="card-title m-0 mt-3">{{ review.title }}</h3>
            <p class="card-subtitle text-center mt-2 align-self-end">
              {{ review.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-lg-4 pb-4" *ngIf="dbService.getReviewBool">
    <div class="col d-flex justify-content-center">
      <button type="button" class="btn btn-lg btn-outline-primary" aria-label="explore1" routerLink="/reviews">
        Explore More
        <span class="bi bi-chevron-double-right"></span>
      </button>
    </div>
  </div>
</div>

<div class="container-fluid  my-5">
  <div class="container">
    <div class="row mx-lg-4 pt-4 pb-2">
      <div class="col">
        <h2 class="text-center display-4 heading-font">Videos</h2>
      </div>
    </div>
    <div class="row mx-lg-4 py-2">
      <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let video of videosList; index as idx">
        <div class="ratio ratio-16x9">
          <iframe class="rounded-2" [src]="video.videoUrl" title="YoutubePlayer" frameborder="0"
          allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="row mx-lg-4 pb-4" *ngIf="dbService.getVideoBool">
      <div class="col d-flex justify-content-center">
        <button type="button" class="btn btn-lg btn-outline-primary" aria-label="explore2" routerLink="/videos">
          Explore More
          <span class="bi bi-chevron-double-right"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row mx-lg-4 py-4">
    <div class="col text-center">
      <span class="text-uppercase m-0 text-muted" style="font-size: 18px;">
        <i class="bi bi-bar-chart-fill mr-1"></i>
        Visits
      </span>
      <h1 class="display-4 heading-font">{{ dbService!.getVisitorCount || 0 }}</h1>
    </div>
  </div>
</div>