import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  videosList: any[] = [];
  constructor(
    private dbService: DbService
  ) { }

  ngOnInit(): void {
    this.getInitialData();
  }

  getInitialData() {
    let sub = this.dbService.homeVideosSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.videosList = [...value];
        this.getRemainingData();
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6) // 60 seconds
      }
    })
  }

  getRemainingData() {
    this.dbService.getAllVideos();
    let sub = this.dbService.videosSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.videosList = this.videosList.concat(value);
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6)
      }
    })
  }
}
