import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { AlbumsComponent } from './pages/albums/albums.component';
import { BranchComponent } from './pages/branch/branch.component';
import { CalculateIELTSComponent } from './pages/calculate-ielts/calculate-ielts.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { EventsComponent } from './pages/events/events.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { JobVacanciesComponent } from './pages/job-vacancies/job-vacancies.component';
import { ServicesComponent } from './pages/service/service.component';
import { TopicsComponent } from './pages/topics/topics.component';
import { BlogsComponent } from "./pages/blogs/blogs.component";
import { SubblogsComponent } from "./pages/subblogs/subblogs.component";
import { ImagesComponent } from './pages/images/images.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { VideosComponent } from './pages/videos/videos.component';
import { NewsComponent } from './pages/news/news.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'service/:serviceId', component: ServicesComponent },
  { path: 'course/:courseId', component: CoursesComponent },
  { path: 'branch/:branchId', component: BranchComponent },
  { path: "blogs/:blogId", component: SubblogsComponent },
  { path: "blogs", component: BlogsComponent },
  { path: 'contact-us', component: ContactusComponent },
  { path: "events/:albumId", component: AlbumsComponent },
  { path: 'events', component: EventsComponent },
  { path: 'frequently-asked-questions', component: FaqComponent },
  { path: 'calculate-ielts', component: CalculateIELTSComponent },
  { path: 'jobs', component: JobVacanciesComponent },
  { path: "cue-card-topics", component: TopicsComponent },
  { path: "writing-task2-topics", component: TopicsComponent },
  { path: "images", component: ImagesComponent },
  { path: "reviews", component: ReviewsComponent },
  { path: "news", component: NewsComponent },
  { path: "videos", component: VideosComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
