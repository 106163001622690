import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ContactModel } from 'src/app/modals/ContactModel';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  MAP_STRING: string = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13695.355514360997!2d75.844638!3d30.891168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a83f32ad2b5cf%3A0xbcecb6b2779c3d50!2sSethi%20Study%20Circle%20-%20SSC%20Head%20Office%20Ludhiana!5e0!3m2!1sen!2sin!4v1678092245051!5m2!1sen!2sin";

  contactModelList: ContactModel[] = [];
  mainBranchInfo: ContactModel = {};
  mapLink!: SafeResourceUrl;

  loading: boolean = false;
  social: any = {};

  constructor(
    private dbService: DbService
  ) {}

  ngOnInit(): void {
    this.mapLink = this.dbService.sanitizer.bypassSecurityTrustResourceUrl(this.MAP_STRING);
    this.getData();
  }

  getData() {
    let contactModelSub = this.dbService.branchesModelSubject.subscribe((list) => {
      if(list !== null) {
        this.contactModelList = list.filter(x => !x.mainBranch);
        this.dbService.getWindowRef().setTimeout(() => contactModelSub.unsubscribe(), this.dbService.timeoutInterval)
      }
    })
    let mainBranchSub = this.dbService.mainBranchModelSub.subscribe((data: any) => {
      if (data != null) {
        this.loading = false;
        this.mainBranchInfo = data;
        this.mapLink = this.dbService.sanitizer.bypassSecurityTrustResourceUrl(this.mainBranchInfo.mapLink ?? this.MAP_STRING);
        this.dbService.getWindowRef().setTimeout(() => mainBranchSub.unsubscribe(), this.dbService.timeoutInterval)
      }
    });

    let sub = this.dbService.socialSubject.subscribe((data) => {
      if(data !== null) {
        this.social = { ...data }
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval)
      }
    });
  }
}
