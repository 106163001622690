import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  courseUrlId!: string;
  safeCourseContent!: SafeHtml;
  courseModel: any = {};

  constructor(
    private route: ActivatedRoute,
    private dbService: DbService
  ) {
    route.params.subscribe((res: any) => {
      this.courseUrlId = res.courseId ?? null;
      this.getData();
    })
  }

  ngOnInit(): void {
    
  }

  getData() {
    let sub = this.dbService.coursesModelSubject.subscribe(list => {
      if(list !== null) {
        this.courseModel = list.find(x => x.courseUrlId === this.courseUrlId);
        this.safeCourseContent = this.dbService.sanitizer.bypassSecurityTrustHtml(this.courseModel?.description ?? "")
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval)
      }
    })

    // this.dbRef.collection(COURSES_COLLECTION).doc(this.courseModel.courseId)
    //   .collection("batches", ref => ref.orderBy('timestamp', 'desc'))
    //   .valueChanges()
    //   .subscribe((value) => {
    //     this.batches = value.map(e => e);
    //   })
  }
}
