import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplyJobDialogComponent } from 'src/app/components/apply-job-dialog/apply-job-dialog.component';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-job-vacancies',
  templateUrl: './job-vacancies.component.html',
  styleUrls: ['./job-vacancies.component.scss']
})
export class JobVacanciesComponent implements OnInit {

  careerModelList: any[] = [];

  constructor(
    private dbService: DbService,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.dbService.getCarrers();
    let sub = this.dbService.careersModelListSubject.subscribe((list) => {
      if(list.length !== 0) {
        this.careerModelList = list.map((e) => ({
          ...e,
          paraSafe: this.dbService.sanitizer.bypassSecurityTrustHtml(e?.description ?? ""),
        }));
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval)
      }
    })
  }

  openCareerModal(careerModel: any) {
    let modalRef = this.modal.open(ApplyJobDialogComponent);
    modalRef.componentInstance.careerModel = careerModel;
  }
}
