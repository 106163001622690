<footer class="">
  <div class="container-fluid">
    <div class="row px-sm-3 py-sm-4">
      <div class="col-12 col-sm-6 col-lg-4 mt-4 mt-lg-0">
        <!-- <img ngSrc="./../../../assets/logos/ssclogo.png" alt="" width="120" height="70" priority> -->
        <!-- <img loading="lazy" src="./../../../assets/ss-white-logo.png" alt="" class="img-fluid" width="100px" height="80px"> -->
        <h5>Sethi Study Circle</h5>
        <span class="text-muted text-wrap" style="font-size: 14px; text-align: justify;">
          SSC is a pioneer organization of learning which was established in
          1980 under the vision of Professor Pritpal Singh Sethi. SSC,
          as an organization, has always believed in quality training with the
          use of latest techniques. The programming of the organization has
          been coined out of western methods of education wonderfully blended
          with Indian psycho-systems.
        </span>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 mt-4 mt-lg-0 d-flex flex-column">
        <h5>Branches</h5>
        <a [routerLink]="['branch', branch.branchUrlId]" *ngFor="let branch of contactModelList" class="text-muted my-1 branch-link" aria-label="branches">{{ branch.title || "" }}</a>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 mt-4 mt-lg-0">
        <h5>Contact Us</h5>
        <div class="d-flex text-muted my-3" style="gap: 10px;">
          <i class="bi bi-house fs-6"></i>
          <span>{{ mainBranchInfo.address || ""}}</span>
        </div>
        <div class="d-flex text-muted my-3" style="gap: 10px;">
          <i class="bi bi-envelope fs-6"></i>
          <a href="{{ 'mailto:'+mainBranchInfo.email }}" aria-label="email">
            {{ mainBranchInfo.email || "" }}
          </a>
        </div>
        <div class="d-flex text-muted my-3" style="gap: 10px;">
          <i class="bi bi-telephone fs-6"></i>
          <a href="{{ 'tel:+91'+mainBranchInfo.phone }}" aria-label="phone">{{ mainBranchInfo.phone || "" }}</a>
        </div>

        <div class="d-flex my-3" style="gap: 14px;">
          <a href="{{ social!.facebook }}" target="_blank" class="text-muted" aria-label="facebook-icon">
            <i class="bi bi-facebook fs-6"></i>
          </a>
          <a href="{{ social!.whatsappNumber }}" target="_blank" class="text-muted" aria-label="whatsapp-icon">
            <i class="bi bi-whatsapp fs-6"></i>
          </a>
          <a href="{{ social!.youtube }}" target="_blank" class="text-muted" aria-label="youtube-icon">
            <i class="bi bi-youtube fs-6"></i>
          </a>
          <a href="{{ social!.instagram }}" target="_blank" class="text-muted" aria-label="instragram-icon">
            <i class="bi bi-instagram fs-6"></i>
          </a>
        </div>
      </div>
      <!-- <div class="col-12 col-sm-6 col-lg-3">
        <iframe [src]="mapLink" width="100%" height="280" style="border: 0" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div> -->
    </div>
  </div>
  <div class="container-fluid mt-2" style="background-color: rgba(0, 0, 0, 0.05)">
    <div class="row text-muted px-2 py-2 px-sm-3 py-sm-4">
      <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-start">
        <span class="text-center text-sm-start">© 2022 Copyright by Sethi Study Circle</span>
        <span>
          All Right Reserved; 
          <a href="./privacy_policy.html" aria-label="Privacy policy">Privacy Policy</a>
        </span>
      </div>
      <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-end">
        <span>
          Designed by
          <a href="https://digimap.in/" target="_blank" class="text-muted" aria-label="digmap technologies">
            Digimap Technologies
          </a>
        </span>
        <span>
          Developed By
          <a href="https://auribises.com/" class="text-muted" target="_blank" aria-label="auribises technologies">
            Auribises Technologies
          </a>
        </span>
      </div>
    </div>
  </div>
</footer>