import { Component } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {

  branchId: string | null = null;
  albums: any[] = [];

  constructor(
    private dbRef: Firestore,
    public dbService: DbService,
    private route: ActivatedRoute
  ) { 
    route.queryParams.subscribe((res: any) => {
      this.branchId = res.branchId ?? null;
      this.getData(this.branchId);
    })
  }

  ngOnInit(): void {
      this.dbService.getAlbumsSubject();
  }

  getData(branchId: string | null) {
    if(branchId === null) {
      this.dbService.getAlbumsSubject();
      this.dbService.albumsSubject.subscribe(list => {
        if(list !== null) {
          this.albums = list
        }
      })
    } else {
      // this.dbRef.collection(contactCollection).doc(branchId)
      // .collection(ALBUMS_COLLECTION)
      // .valueChanges()
      // .subscribe((value) => {
      //   this.albums = value.map(e => e as GalleryModel)
      // })
    }
  }

}
