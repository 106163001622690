<div class="modal-header">
  <h4 class="modal-title">Send Application</h4>
  <button type="button" class="btn-close" aria-label="Close" id="closeAddNewStaffModal" #closeAddNewStaffModal
    (click)="modal.dismiss('Cross click')">
    <!-- <span aria-hidden="true">&times;</span> -->
  </button>
</div>
<form [formGroup]="careerForm" (ngSubmit)="saveUserApplication()">
  <div class="modal-body">
    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="name">Applying For</label>
      <input type="text" name="name" formControlName="careerName" class="form-control" readonly>
    </div>

    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="name">Name *</label>
      <input type="text" name="name" formControlName="name" id="name" class="form-control"
        [class.invalid]="careerForm.get('name')?.invalid && ( careerForm.get('name')?.dirty || careerForm.get('name')?.touched )">
      <div class="text-danger mt-1" style="font-size: 14px;"
        *ngIf="careerForm.get('name')?.invalid && ( careerForm.get('name')?.dirty || careerForm.get('name')?.touched )">
        <div *ngIf="careerForm.get('name')?.hasError('required')">Name is Required</div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="email">Email *</label>
      <input type="email" name="email" formControlName="email" id="email" class="form-control"
        [class.invalid]="careerForm.get('email')?.invalid && ( careerForm.get('email')?.dirty || careerForm.get('email')?.touched )">
      <div class="text-danger mt-1" style="font-size: 14px;"
        *ngIf="careerForm.get('email')?.invalid && ( careerForm.get('email')?.dirty || careerForm.get('email')?.touched )">
        <div *ngIf="careerForm.get('email')?.hasError('required')">Email Address is Required</div>
        <div *ngIf="careerForm.get('email')?.hasError('email')">Email Address is baddly formatted</div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="phoneNumber">Phone Number *</label>
      <input type="tel" name="phone" formControlName="phone" id="phoneNumber" class="form-control"
        [class.invalid]="careerForm.get('phone')?.invalid && ( careerForm.get('phone')?.dirty || careerForm.get('phone')?.touched )">
      <div class="text-danger mt-1" style="font-size: 14px;"
        *ngIf="careerForm.get('phone')?.invalid && ( careerForm.get('phone')?.dirty || careerForm.get('phone')?.touched )">
        <div *ngIf="careerForm.get('phone')?.hasError('required')">Phone Number is Required</div>
        <div *ngIf="careerForm.get('phone')?.hasError('minlength')">Phone Number should be 10 characters</div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="services">Prefered Location *</label>
      <select name="service" id="service" formControlName="preferedLocation" class="form-select"
        [class.invalid]="careerForm.get('preferedLocation')?.invalid && ( careerForm.get('preferedLocation')?.dirty || careerForm.get('preferedLocation')?.touched )">
        <option *ngFor="let loc of careerModel.locations; index as idx" [value]="loc">
          {{ loc | titlecase }}
        </option>
      </select>
      <div class="text-danger mt-1" style="font-size: 14px;"
        *ngIf="careerForm.get('preferedLocation')?.invalid && ( careerForm.get('preferedLocation')?.dirty || careerForm.get('preferedLocation')?.touched )">
        <div *ngIf="careerForm.get('preferedLocation')?.hasError('required')">Please Select Location</div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="services">Select Resume *</label>
      <div class="custom-file">
        <input type="file" class="form-control" id="customFile" (change)="checkFileType($event)">
        <!-- <label class="custom-file-label" for="customFile">{{ tempImageFile?.name || ""}}
          {{ tempImageFile === null ? 'Choose file' : tempImageFile?.name }}
        </label> -->
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="text-sm m-0 mb-1" for="message">Message</label>
      <textarea name="message" formControlName="message" class="form-control" rows="3"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn custom-outline-btn w-100 d-flex align-items-center justify-content-center gap-2" aria-label="submit" [disabled]="loader">
      Submit
      <div class="spinner-border spinner-border-sm ml-1" role="status" *ngIf="loader"></div>
    </button>
  </div>
</form>