<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <div style="
        background: url('./../../../assets/heads/services1.webp') no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 250px;
      ">
      </div>
      <!-- <img loading="lazy" src="./../../../assets/services.jpg" alt="" class="img-fluid"
        style="height: 250px; width: 100%; object-fit: cover; border-radius: 0;"> -->
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col" >
      <div style="height: 100px; gap: 8px;" class="d-flex  align-items-center rounded shadow-sm p-3"
        [ngStyle]="{
          'background': serviceModel?.bannerBgColor || '#f1f1f1'
        }">
        <i class="bi bi-airplane fs-2" style="transform: rotateZ(45deg);"></i>
        <h2 class="m-0">{{ serviceModel?.title || ""}}</h2>
      </div>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-self-center">
        <div [innerHTML]="safeServiceContent"></div>
      </div>
    </div>
  </div>
</div>