<div class="container my-5">
  <div class="row">
    <div class="col">
      <h5 class="display-4 heading-font text-center">Blog Details</h5>
      <p class="text-center text-muted">Sethi Study Circle</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <div class="card shadow-none">
        <img loading="eager" [src]="blogs.imageUrl" alt="blog" class="img-fluid" height="200px" width="100%">
        <div class="card-body p-4">
          <h4>{{ blogs.title }}</h4>
          <p [innerHTML]="blogs.description"></p>
        </div>
      </div>
    </div>
  </div>
</div>