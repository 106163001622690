import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptimizedImage } from '@angular/common';
import { ApplyJobDialogComponent } from './apply-job-dialog/apply-job-dialog.component';
import { EnquiryDialogComponent } from './enquiry-dialog/enquiry-dialog.component'
import { ReactiveFormsModule } from '@angular/forms';
import { PopupComponent } from './popup/popup.component';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    ApplyJobDialogComponent,
    EnquiryDialogComponent,
    PopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    NgbCollapseModule,
    NgbOffcanvasModule,
    ReactiveFormsModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    ApplyJobDialogComponent,
    EnquiryDialogComponent
  ]
})
export class ComponentsModule { }
