<div class="container my-5">
  <div class="row mx-lg-4">
    <div class="col d-flex flex-column">
      <span class="display-4 heading-font text-center">Latest News</span>
      <span class="text-center">Here comes our latest updates...</span>
    </div>
  </div>
  <div class="row mx-lg-4 mt-3">
    <div class="col-12" *ngFor="let newEle of newsList">
      <div class="card mb-3">
        <div class="card-body">
          <h3 class="heading-font d-none d-lg-block">{{ newEle.newsTitle }}</h3>
          <h5 class="heading-font d-block d-lg-none">{{ newEle.newsTitle }}</h5>
          <div class="d-flex flex-column flex-lg-row align-items-start gap-3 mt-3">
            <img [src]="newEle.newsImageUrl" loading="eager" [alt]="newEle.newsTitle" class="img-fluid rounded-3 d-none d-lg-block" width="200" height="100">
            <img [src]="newEle.newsImageUrl" loading="eager" [alt]="newEle.newsTitle" class="img-fluid rounded-3 d-block d-lg-none" width="100%" height="100">
            <div class="flex-grow-lg-1" [innerHTML]="newEle.paraSafe"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>