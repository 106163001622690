import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  popupModel: any = {}

  constructor(
    public modal: NgbActiveModal,
    public dbService: DbService
  ) {}

  ngOnInit(): void {
    
  }

}
