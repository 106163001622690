<div class="container-fluid">
    <div class="row">
      <div class="col p-0">
        <div style="
          background: url('./../../../assets/heads/faqs1.webp') no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 250px;
        ">
        </div>
        <!-- <img loading="lazy" src="./../../../assets/images/banner9.jpg" alt="" class="img-responsive"
          style="height: 250px; width: 100%; object-fit: fill; border-radius: 0;"> -->
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="row">
      <div class="col">
        <div class="d-flex flex-column justify-content-center align-self-center">
          <h2 class="m-0 mb-1">Exam Topics</h2>
          <h5 class="text-muted m-0 mb-4">{{ titleString | titlecase }}</h5>
          
          <p *ngIf="topicLists.length === 0">No Topics Availables right now</p>
          <div class="table-responsive" *ngIf="topicLists.length !== 0">
            <table class="table table-flush table-borderless">
              <thead>
                <th>Date</th>
                <th>Topic</th>
              </thead>
              <tbody>
                <tr *ngFor="let topic of topicLists;">
                  <td>{{ topic.examDate.toDate() | date: 'dd/MM/yyyy' }}</td>
                  <td><div [innerHTML]="topic.paraSafe" class="text-wrap text-sm"></div></td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>
  