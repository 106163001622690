<nav class="navbar navbar-expand-lg navbar-light w-100 ">
  <div class="container-fluid d-flex flex-row flex-lg-column justify-content-between align-items-start">
    <div class="d-flex align-items-center justify-content-between w-100">
      <a class="d-flex nav-link p-0" [routerLink]="['/']" (click)="isMenuCollapsed = true" aria-label="navbar">
        <img src="./../../../assets/logos/ssclogo2.webp" alt="images" width="120px" height="70px" priority style="aspect-ratio: 21 / 9;">
        <!-- <span class="text-dark align-self-end" style="font-size: 24px; font-family: serif; margin-left: -12px;">Sethi Study Circle</span> -->
      </a>
      <button type="button" class="btn btn-sm custom-outline-btn rounded-pill d-none d-lg-block" aria-label="toogle-navigation" routerLink="/calculate-ielts">Calculate IELTS Bands</button>
      <button class="navbar-toggler align-self-start my-auto" type="button" (click)="toggleOffcanvas()">
        &#9776;
      </button>
    </div>
    <div class="collapse navbar-collapse w-100" [ngbCollapse]="isMenuCollapsed">
      <div class="d-flex flex-column flex-md-row justify-content-between" style="width: inherit;">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="is-active" aria-label="home navlink" routerLink="/" [routerLinkActiveOptions]="{exact:true}">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="is-active" aria-label="about navlink" routerLink="/about-us">About Us</a>
          </li>
          <li class="nav-item">
            <div class="dropdown"> 
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" style="cursor: pointer;" aria-label="services navlink">
                Services
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item custom-bg-hover" aria-label="service dropdown menu" *ngFor="let service of dbService.servicesModelSubject | async; index as idx" [routerLink]="['service', service.serviceUrlId]" routerLinkActive="is-active">
                  {{ service.title }}
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" style="cursor: pointer;" aria-label="courses navlink">
                Courses
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item custom-bg-hover" aria-label="course dropdown menu" *ngFor="let course of dbService.coursesModelSubject | async; index as idx" [routerLink]="['course', course.courseUrlId]" routerLinkActive="is-active">
                  {{ course.title }}
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="nav-link dropdown-toggle" aria-label="branches navlink" data-bs-toggle="dropdown" style="cursor: pointer;">
                Branches
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item custom-bg-hover" aria-label="branches dropdownmenu" *ngFor="let branch of dbService.branchesModelSubject | async" [routerLink]="['branch', branch.branchUrlId]" routerLinkActive="is-active">
                  {{ branch.title }}
                </a>
                
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="is-active" routerLink="/contact-us" aria-label="contact navlink">Contact Us</a>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="nav-link dropdown-toggle" aria-label="more dropdownmenu" data-bs-toggle="dropdown" style="cursor: pointer;">
                More
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item custom-bg-hover" aria-label="news" routerLink="/news" routerLinkActive="is-active">News</a>
                <a class="dropdown-item custom-bg-hover" aria-label="events" routerLink="/events" routerLinkActive="is-active">Events</a>
                <a class="dropdown-item custom-bg-hover" aria-label="blogs" routerLink="/blogs" routerLinkActive="is-active">Blogs</a>
                <a class="dropdown-item custom-bg-hover" aria-label="faq" routerLink="/frequently-asked-questions" routerLinkActive="is-active">FAQs</a>
              </div>
            </div>
          </li>
        </ul>
        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center" style="gap: 6px;">
          <div class="dropdown" data-bs-toggle="dropdown" *ngIf="(dbService.brochuresModelSubject | async)?.length !== 0">
            <button type="button" class="btn btn-sm custom-outline-btn rounded-pill dropdown-toggle" aria-label="dropdown1">Brochures</button>
            <div class="dropdown-menu dropdown-menu-lg-right">
              <ng-container *ngFor="let brochure of dbService.brochuresModelSubject | async; index as idx">
                <a (click)="openBrochureInTab(brochure.imageUrl)" class="dropdown-item custom-bg-hover" aria-label="brochure" target="_blank">
                  {{ brochure.title }}
                </a>
              </ng-container>
            </div>
          </div>
          <div class="dropdown">
            <button type="button" data-bs-toggle="dropdown" class="btn btn-sm custom-outline-btn rounded-pill dropdown-toggle" aria-label="dropdown2">Exam Topics</button>
            <div class="dropdown-menu">
              <!-- <a class="dropdown-item custom-bg-hover" routerLink="/cue-card-topics" routerLinkActive="is-active">Cue Cards</a> -->
              <a class="dropdown-item custom-bg-hover" routerLink="/writing-task2-topics" aria-label="writing task" routerLinkActive="is-active">Writing Task 2</a>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-sm custom-outline-btn rounded-pill" routerLink="/calculate-ielts">Calculate IELTS Bands</button> -->
          <button type="button" class="btn btn-sm custom-btn rounded-pill" routerLink="/jobs" aria-label="vacancy">Job Vacancies</button>
          <button type="button" class="btn btn-sm custom-btn rounded-pill" (click)="openInquiryModal()" aria-label="enquiry">Enquire Now</button>
        </div>
      </div>
      <!-- <button type="button" class="btn btn-sm button rounded-pill mt-2 mt-sm-0 d-block d-sm-none" style="background: #f18067; color: #fff;">Enquire Now</button> -->
    </div>
  </div>
</nav>