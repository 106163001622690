<div class="container my-5">
  <div class="row mx-lg-4 pt-4 pb-2">
    <div class="col">
      <h2 class="text-center display-4 heading-font">Videos</h2>
    </div>
  </div>
  <div class="row mx-lg-4 py-2">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let video of videosList; index as idx">
      <div class="ratio ratio-16x9">
        <iframe class="rounded-2" [src]="video.videoUrl" title="YoutubePlayer" frameborder="0"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>