<div class="container-fluid" style="width: 100%;">
  <div class="row">
    <div class="col p-0">
      <img src="./../../../assets/heads/branch1.webp" class="img-responsive" style="height: 250px; width: 100%; object-fit: cover; object-position: center;" alt="image">
      <!-- <img src="./../../../assets/heads/branch.jpg" width="100%" height="100" alt="" loading="eager"> -->
      <!-- <div style="
        background: url('./../../../assets/heads/branch.jpg') no-repeat;
        background-position: center;
        background-size: fill;
        width: 100%;
        height: 250px;
        padding: 0;
      ">
      </div> -->
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-self-center">
        <!-- <h2>IELTS CENTER - {{ branchModel.title }}</h2> -->
        <div class="card my-3 mb-2">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h2>IELTS CENTER @{{ branchModel?.title }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="d-flex text-muted my-2" style="gap: 10px;">
                  <i class="bi bi-house fs-6"></i>
                  <span>{{ branchModel?.address }}</span>
                </div>
                <div class="d-flex text-muted my-2" style="gap: 10px;">
                  <i class="bi bi-envelope fs-6"></i>
                  <a [href]="'mailto:'+branchModel?.email" aria-label="email">
                    {{ branchModel?.email || "" }}
                  </a>
                </div>
                <div class="d-flex text-muted my-2 mb-3" style="gap: 10px;">
                  <i class="bi bi-telephone fs-6"></i>
                  <a [href]="'tel:+91'+branchModel?.phone" aria-label="phone2">{{ branchModel?.phone || "" }}</a>
                </div>
              </div>
              <div class="col-12 col-sm-6 position-relative">
                <div class="spinner-border position-absolute top-50 start-50 mx-auto" *ngIf="isIframeLoadedBool" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
    
                <!-- <img src="./../../../assets/loading.svg" class="img-fluid" alt="" height="200px" width="100%" *ngIf="isIframeLoadedBool"> -->
                <iframe [src]="branchMapLink" #mapLinkIframe frameBorder="0" [ngStyle]="{
                  'visibility': isIframeLoadedBool ? 'hidden': ''
                }" (load)="isIframeLoaded()"
                  referrerpolicy="no-referrer-when-downgrade" height="300" style="width: 100%;"></iframe>
              </div>
            </div>

            <!-- srcdoc='
                <div class="spinner-border position-absolute top-50 left-50 mx-auto" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                ' -->
            <!-- <div class="row mt-4"  *ngIf="slider.length !== 0"> -->
              <!-- <div class="col">
                <ngb-carousel interval="5000" [keyboard]="true"
                    [pauseOnHover]="true" [wrap]="true">
                    <ng-template ngbSlide *ngFor="let item of slider">
                      <div class="picsum-img-wrapper">
                        <img loading="lazy" [src]="item.imageUrl" class="rounded" style="height: 250px; width: 100%; object-fit: cover; object-position: center;" />
                        <img loading="lazy" [src]="item.imageUrl" class="rounded d-block d-lg-none" style="height: 250px; width: 100%; object-fit: cover; object-position: center;" />
                      </div>
                    </ng-template>
                  </ngb-carousel>
              </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>