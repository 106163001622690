<div class="container my-5">
  <div class="row">
    <div class="col">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h4 class="rounded py-3 px-1 w-75 text-center bg-primary text-white">Calculate IELTS Band</h4>

        <div class="card w-75 mt-4" *ngIf="nextStep === 5">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <p class="m-0">Your IELTS Band</p>
              <span class="display-3 text-primary">{{ calculatedBand || 0 }}</span>
            </div>
            <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center flex-wrap mt-3" style="gap: 8px;">
              <div class="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
                <span class="h5 text-muted">Listening</span>
                <span class="h3 m-0">{{ getListeningBand(listeningScore!) || 0 }}</span>
              </div>
              <div class="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
                <span class="h5 text-muted">Reading</span>
                <span class="h3 m-0">{{ paperType ? getGeneralBand(readingScore!) : getReadingAcadmicBand(readingScore!) || 0 }}</span>
              </div>
              <div class="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
                <span class="h5 text-muted">Writing</span>
                <span class="h3 m-0">{{ writtingBand }}</span>
              </div>
              <div class="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
                <span class="h5 text-muted">Speaking</span>
                <span class="h3 m-0">{{ speakingBand || 0 }}</span>
              </div>
            </div>
          </div>
          <div class="card-footer bg-white border-0">
            <button type="button" class="btn nav-link text-info mx-auto" aria-label="bands" (click)="resetField()">
              <i class="bi bi-arrow-clockwise mr-1"></i>
              Re-calculate Bands
            </button>
          </div>
        </div>

        <div class="card w-75 mt-4" *ngIf="nextStep !== 5">
          <div class="card-body">
            <div class="form-group" *ngIf="nextStep === 1">
              <label for="listening" class="custom-label" >Listening Score</label>
              <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="listeningScore">
                <span class="input-group-text bg-white" style="font-size: 14px;">(0 -&nbsp;40)</span>
              </div>
            </div>
            <div class="form-group" *ngIf="nextStep === 2">
              <label for="listening" class="custom-label">Reading Score</label>
              <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="readingScore">
                <span class="input-group-text bg-white" style="font-size: 14px;">(0 -&nbsp;40)</span>
              </div>
            </div>
            <div class="form-group" *ngIf="nextStep === 3">
              <label for="listening" class="custom-label">Writting Band</label>
              <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="writtingBand">
                <span class="input-group-text bg-white" style="font-size: 14px;">(0 -&nbsp;9)</span>
              </div>
            </div>
            <div class="form-group" *ngIf="nextStep === 4">
              <label for="listening" class="custom-label">Speaking Band</label>
              <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="speakingBand">
                <span class="input-group-text bg-white" style="font-size: 14px;">(0 -&nbsp;9)</span>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="nextStep === 0">
              <label class="form-label">IELTS Paper</label>
              <div class="btn-group mt-2">
                <input type="radio" [value]="0" class="btn-check"  [(ngModel)]="paperType" id="academic">
                <label class="btn btn-md no-hover-effects btn-outline-primary " for="academic">Academic</label>

                <input type="radio" [value]="1" class="btn-check"  [(ngModel)]="paperType" id="general">
                <label class="btn btn-md no-hover-effects btn-outline-primary" for="general">General</label>
              </div>
            </div>
          </div>
          <div class="card-footer bg-white border-0">
            <div class="d-flex" [ngClass]="{
              'justify-content-between': nextStep !== 0,
              'justify-content-center': nextStep === 0
            }">
              <button type="button" class="btn custom-btn" aria-label="decrement" (click)="decrementStep()" *ngIf="nextStep !== 0">Back</button>
              <button type="button" class="btn custom-btn" aria-label="increment" (click)="incrementStep()" *ngIf="nextStep !== 4">
                {{ nextStep === 0 ? 'Proceed' : 'Next' }}
              </button>
              <button type="button" class="btn custom-btn" aria-label="calculate" (click)="calcualteScore()" *ngIf="nextStep === 4">Calculate</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>