import { Component } from '@angular/core';
import { CollectionReference, DocumentData, doc, Timestamp, setDoc } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { QUERIES_COLLECTION } from 'src/app/constants';
import { ContactModel } from 'src/app/modals/ContactModel';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {
  
  queryForm: FormGroup = new FormGroup({});
  formSubmitted: boolean = false;

  contactModel: ContactModel = {};
  contactList: ContactModel[] = [];

  contactModelList: ContactModel[] = [];
  mainBranch: ContactModel = {};
  mapLink!: SafeResourceUrl;

  collectionRef!: CollectionReference<DocumentData>

  constructor(
    private fb: FormBuilder,
    public dbService: DbService,
    private domSanitizer: DomSanitizer,
    private toast: ToastrService
  ) {}

  getMapLink(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.collectionRef = this.dbService.getCollectionRef(QUERIES_COLLECTION);
    this.initForm();
  }

  initForm() {
    this.queryForm = this.fb.group({
      queryId: [doc(this.collectionRef).id],
      name: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      query: ["", Validators.required],
      date: [Timestamp.now()]
    });

    let contactSub = this.dbService.branchesModelSubject.subscribe((data) => {
      if (data != null) {
        data = data.map(e => ({
          ...e,
          urlSafe: this.dbService.sanitizer.bypassSecurityTrustResourceUrl(e!.mapLink ?? "")
        }))
        this.contactList = data.filter(x => !x.mainBranch).map(e => e);
        this.dbService.getWindowRef().setTimeout(() => contactSub.unsubscribe(), this.dbService.timeoutInterval);
      }
    });

    let mainBranchSub = this.dbService.mainBranchModelSub.subscribe(data => {
      if(data !== null) {
        this.mainBranch = { ...data }
        this.mapLink = this.domSanitizer.bypassSecurityTrustResourceUrl(this.mainBranch?.mapLink ?? "");
        this.dbService.getWindowRef().setTimeout(() => mainBranchSub.unsubscribe(), this.dbService.timeoutInterval);
      }
    })
  }

  async onSubmit(event: any) {
    event.preventDefault();
    let values = { ...this.queryForm.value };

    let docRef = doc(this.collectionRef, values.queryId);
    setDoc(docRef, { ...values }, { merge: true })
      .then(() => {
        this.initForm();
        this.toast.success("Query Submitted", "Success");
      }, (error) => {
        this.initForm();
        this.toast.error("Unable to submit the form", "Error")
      })
    // await this.dbService.sendQuery(this.queryForm);
    // this.initForm();
  }
}
