export const environment = {
  firebase: {
    projectId: 'sethi-study-circle',
    appId: '1:909067802115:web:ef1cd886d4186b0abce036',
    storageBucket: 'sethi-study-circle.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDRD8Lwd-nwFc3qLyF6-dWgxy5Bk3PQa-Q',
    authDomain: 'sethi-study-circle.firebaseapp.com',
    messagingSenderId: '909067802115',
    measurementId: 'G-M6XTBG4S43',
  },
  production: true
};
