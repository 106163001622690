import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';
import { DbService } from 'src/app/services/db.service';
import { EnquiryDialogComponent } from '../enquiry-dialog/enquiry-dialog.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  isMenuCollapsed: boolean = true

  brochures: any[] = [];
  branches: any[] = [];
  courses: any[] = [];
  services: any[] = [];

  constructor(
    private router: Router,
    private offcanvas: NgbOffcanvas,
    public dbService: DbService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.dbService.getBranches();
    this.dbService.getBrochures();
    this.dbService.getCourses();
    this.dbService.getServices();
    this.router.events.pipe(filter(x => x instanceof NavigationEnd))
    .subscribe((value) => {
      this.offcanvas.dismiss('navitem-clicked');
    })
  }

  toggleOffcanvas() {
    this.offcanvas.open(SidebarComponent, {
      panelClass: 'offcanvasbody' 
    })
  }

  openInquiryModal() {
    let modalRef = this.modal.open(EnquiryDialogComponent);
    modalRef.componentInstance.inModalBool = true;
  }

  openBrochureInTab(url: string) {
    window.open(url, '_blank')
  }
}
