<div class="container my-5">
  <div class="row mx-lg-4 pt-4 pb-2">
    <div class="col">
      <h2 class="text-center display-4 heading-font">Student Reviews</h2>
    </div>
  </div>
  <div class="row mx-lg-4 py-2">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let review of reviewsList; index as idx">
      <div class="card" style="height: 100%;">
        <div class="card" style="height: 100%;">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center" style="height: 100%;">
              <img loading="eager" [src]="review.imageUrl || '../../../assets/static/empty-profile.png'" alt="review" class="img-fluid rounded-3" width="100" height="100" >
              <h3 class="card-title m-0 mt-3">{{ review.title }}</h3>
              <p class="card-subtitle text-center mt-2 align-self-end">
                {{ review.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>