<div [ngClass]="{
  'modal-header border-0': inModalBool,
  'card-header border-0 bg-white': !inModalBool
}">
  <h4 class="modal-title" id="modal-basic-title">{{ inModalBool ? 'Enquire Now' : 'Send Enquiry' }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismissAll()" *ngIf="inModalBool"></button>
</div>
<form [formGroup]="enquiryForm" (ngSubmit)="onSubmitEnquiry()">
  <div [ngClass]="{
    'modal-body': inModalBool,
    'card-body': !inModalBool
  }">
    <div class="col mb-3">
      <label style="font-size: 14px;" class=" m-0 mb-1" for="name">Name *</label>
      <input type="text" name="name" id="name" formControlName="name" class="form-control form-control-sm"
        [class.invalid]="enquiryForm.get('name')?.invalid && ( enquiryForm.get('name')?.dirty || enquiryForm.get('name')?.touched )">
      <div class="text-danger mt-1" style="font-size: 12px;"
        *ngIf="enquiryForm.get('name')?.invalid && ( enquiryForm.get('name')?.dirty || enquiryForm.get('name')?.touched )">
        <div *ngIf="enquiryForm.get('name')?.hasError('required')">Name is Required</div>
        <div *ngIf="enquiryForm.get('name')?.hasError('pattern')">Only Alphabets</div>
      </div>
    </div>
    <div class="col mb-3">
      <label style="font-size: 14px;" class=" m-0 mb-1" for="email">Email *</label>
      <input type="email" name="email" id="email" formControlName="email" class="form-control form-control-sm"
        [class.invalid]="enquiryForm.get('email')?.invalid && ( enquiryForm.get('email')?.dirty || enquiryForm.get('email')?.touched )">
      <div class="text-danger mt-1" style="font-size: 12px;"
        *ngIf="enquiryForm.get('email')?.invalid && ( enquiryForm.get('email')?.dirty || enquiryForm.get('email')?.touched )">
        <div *ngIf="enquiryForm.get('email')?.hasError('required')">Email is Required</div>
        <div *ngIf="enquiryForm.get('email')?.hasError('email')">Email is badly formatted</div>
      </div>
    </div>
    <div class="col mb-3">
      <label style="font-size: 14px;" class=" m-0 mb-1" for="phoneNumber">Phone Number *</label>
      <input type="text" name="phone" id="phoneNumber" formControlName="phone" class="form-control form-control-sm"
        [class.invalid]="enquiryForm.get('phone')?.invalid && ( enquiryForm.get('phone')?.dirty || enquiryForm.get('phone')?.touched )">
      <div class="text-danger mt-1" style="font-size: 12px;"
        *ngIf="enquiryForm.get('phone')?.invalid && ( enquiryForm.get('phone')?.dirty || enquiryForm.get('phone')?.touched )">
        <div *ngIf="enquiryForm.get('phone')?.hasError('required')">Phone is Required</div>
        <div
          *ngIf="enquiryForm.get('phone')?.hasError('pattern') || enquiryForm.get('phone')?.hasError('maxlength') || enquiryForm.get('phone')?.hasError('minlength')">
          Enter 10 digits valid phone number</div>
      </div>
    </div>
    <div class="col mb-3">
      <label style="font-size: 14px;" class=" m-0 mb-1" for="services">Select Option *</label>
      <div class="row">
        <div class="col-12 col-sm-5">
          <div class="form-check" *ngFor="let course of courses; index as idx">
            <input type="checkbox" class="form-check-input" name="services" [id]="course+'_'+inModalBool"
              (change)="updateSelectedServices($event, course)" [checked]="checkedSelectedServices(course)">
            <label class="form-check-label" [for]="course+'_'+inModalBool">{{ course }}</label>
          </div>
        </div>
        <div class="col-12 col-sm-7">
          <div class="form-check" *ngFor="let service of services; index as idx">
            <input type="checkbox" class="form-check-input" name="services" [id]="service+'_'+inModalBool"
              (change)="updateSelectedServices($event, service)" [checked]="checkedSelectedServices(service)">
            <label class="form-check-label" [for]="service+'_'+inModalBool">{{ service }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <label style="font-size: 14px;" class=" m-0 mb-1" for="message">Message</label>
      <textarea name="message" formControlName="message" id="message" class="form-control form-control-sm" id="message" rows="2"
        [class.invalid]="enquiryForm.get('message')?.invalid && ( enquiryForm.get('message')?.dirty || enquiryForm.get('message')?.touched )"></textarea>
    </div>
    <div class="col mb-3 d-flex justify-content-between" *ngIf="showRequestCallbackBool">
      <label style="font-size: 14px;" class=" m-0 align-self-center">Do you need callback?</label>
      <div class="btn-group mt-2">
        <input id="yes" class="btn-check" type="radio" [value]="true" formControlName="requestCallback">
        <label for="yes" class="btn btn-sm btn-outline-primary">Yes</label>

        <input id="no" class="btn-check" type="radio" [value]="false" formControlName="requestCallback">
        <label for="no" class="btn btn-sm btn-outline-primary">No</label>
      </div>
    </div>
  </div>
  <div [ngClass]="{
    'modal-footer border-0': inModalBool,
    'card-footer border-0 bg-white': !inModalBool
  }">
    <button type="submit" class="btn w-100 custom-outline-btn mb-3" aria-label="submit">Send Now</button>
  </div>
</form>