<div class="container my-5">
    <div class="row ">
      <div class="col px-0">
        <div class="card border-0">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6 order-1 order-sm-0 mt-2 mt-sm-0">
                <h3 class="heading-font">{{ albumInfo.title || "" }}</h3>
                <p class="text-justify">{{ albumInfo.description }}</p>
                <div class="d-flex flex-column flex-sm-row align-items-stretch mt-4" style="gap: 12px;">
                  <a [href]="(dbService.socialSubject | async)?.facebook || ''" target="_blank" class="btn btn-md w-100 rounded custom-outline-btn" aria-label="facebook">
                    <i class="bi bi-facebook mr-1"></i>
                    Facebook
                  </a>
                  <a [href]="'tel:+91'+(dbService.mainBranch | async)?.phone || ''" class="btn btn-md w-100 rounded custom-outline-btn" aria-label="callus">
                    <i class="bi bi-telephone mr-1"></i>
                    Call Us
                  </a>
                  <a [href]="(dbService.socialSubject | async)?.instagram || ''" target="_blank" class="btn btn-md w-100 rounded custom-outline-btn" aria-label="instagram">
                    <i class="bi bi-instagram mr-1"></i>
                    Instagram
                  </a>
                </div>
              </div>
              <div class="col-12 col-sm-6 order-0 order-sm-1 ">
                <img loading="lazy" [src]="albumInfo.imageUrl" alt="img" class="img-fluid rounded" style="height: 300px; width: 100%; object-fit: contain;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4">
    <div class="row">
      <div class="col">
        <h5 class="text-center text-muted">Event Images/Videos</h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col" *ngIf="mediaList.length === 0">
        <p class="text-muted text-center text-sm">Event images or videos are not available</p>
      </div>
      <ng-container *ngIf="mediaList.length !== 0">
        <div class="col-12 col-sm-6 col-lg-4" *ngFor="let media of mediaList; index as idx">
          <div class="ratio ratio-16x9" *ngIf="media.videoUrl !== ''">
            <iframe [src]="media.urlSafe" [id]="'video'+idx" allowfullscreen class="rounded-3 m-0" frameborder="0"></iframe>
          </div>
          <div class="card rounded-3" *ngIf="media.imageUrl !== ''">
            <img loading="eager" [src]="media.imageUrl" [alt]="media.title" class="card-img-top rounded-3">
          </div>
        </div>
      </ng-container>
    </div>
  </div>