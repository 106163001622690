import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TOPICS_COLLECTION } from 'src/app/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { DbService } from 'src/app/services/db.service';


@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent {

  titleString: string = '';
  url: string = '';
  topicType: { [key: string]: boolean } = {
    'cue-card': true,
    'writing-task2': false,
  }

  topicLists: any[] = [];

  constructor(
    private router: Router,
    private dbService: DbService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.url = this.router.url.replace("/", "").replace("-topics", "")
    this.titleString = this.url.split("-").join(" ")
    this.getTopics()
  }

  getTopics() {
    this.dbService.getTopics(this.topicType[this.url]);
    let sub = this.dbService.topicsModelListSubject.subscribe(list => {
      if(list !== null) {
        this.topicLists = list.map(e => ({
          ...e,
          paraSafe: this.dbService.sanitizer.bypassSecurityTrustHtml(e?.topic ?? "")
        }));
        // this.safeCourseContent = 
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval)
      }
    })
  }

}
