import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { 
  collection, CollectionReference, doc, 
  DocumentData, Firestore, getDoc, getDocs, limit, onSnapshot, 
  orderBy, Query, query, QueryDocumentSnapshot, startAfter, updateDoc, where, increment
} from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { PopupComponent } from '../components/popup/popup.component';
import * as CONSTANTS from '../constants';
import { ContactModel } from '../modals/ContactModel';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  backgroundBg: string[] = [
    "#D91A731A",
    "#3B8DBF1A",
    "#90BF2A1A",
    "#D949291A",
    "#FCB6671A",
    "#8741821A",
    "#24AEA71A",
    "#0E923F1A"
  ];

  // Navigation Data
  coursesModelSubject = new BehaviorSubject<any[]>([]);
  servicesModelSubject = new BehaviorSubject<any[]>([]);
  brochuresModelSubject = new BehaviorSubject<any[]>([]);
  branchesModelSubject = new BehaviorSubject<any[]>([]);
  mainBranch = new BehaviorSubject<ContactModel>({});

  //Footer Content
  mainBranchModelSub = new BehaviorSubject<any>(null);

  // Social Link, Quote
  socialSubject = new BehaviorSubject<any>(null);
  quoteSubject = new BehaviorSubject<any>(null);

  // FAQ and Job Vacancies
  faqModelListSubject = new BehaviorSubject<any[]>([]);
  careersModelListSubject = new BehaviorSubject<any[]>([]);
  
  // Exams Topic Content
  topicsModelListSubject = new BehaviorSubject<any[]>([]);
  
  // Events, Blogs, Sliders, Blogs
  albumsSubject = new BehaviorSubject<any[]>([]);
  slidersSubject = new BehaviorSubject<any[]>([]);
  blogsSubject = new BehaviorSubject<any[]>([]);
  newsSubject = new BehaviorSubject<any[]>([]);

  // Gallery with LastDoc
  homeGallerySubject = new BehaviorSubject<any[]>([]);
  gallerySubject = new BehaviorSubject<any[]>([]);
  galleryLastDoc = new BehaviorSubject<QueryDocumentSnapshot<DocumentData> | null>(null);

  // Review with LastDoc
  homeReviewSubject = new BehaviorSubject<any[]>([]);
  reviewSubject = new BehaviorSubject<any[]>([]);
  reviewLastDoc = new BehaviorSubject<QueryDocumentSnapshot<DocumentData> | null>(null);

  // Videos with LastDoc
  homeVideosSubject = new BehaviorSubject<any[]>([]);
  videosSubject = new BehaviorSubject<any[]>([]);
  videosLastDoc = new BehaviorSubject<QueryDocumentSnapshot<DocumentData> | null>(null);

  // blogRetrieved: boolean = false;
  $HOME_DOC_LIMIT: number = 6;
  $DOC_LIMIT: number = 50;

  // Load More Bools for various sections
  isImagesAvailable: boolean = true;
  isReviewsAvailable: boolean = true;
  isNewsAvailable: boolean = true;
  isVideosAvailable: boolean = true;

  // Visitor Count
  visitorCount: number = 0;

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private firestore: Firestore,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) { 
    // this.openPopupModal();
    // this.getQuote();
    // this.getFAQs();
    // this.getCarrers();
    this.getSocialUrl();
    // this.getAlbumsSubject();
    this.updateVisitorCount();
  }

  getWindowRef = (): Window => this._doc.defaultView as Window;
  getCollectionRef = (collectionName: string): CollectionReference<DocumentData> => collection(this.firestore, collectionName);
  getQueryRef(collectionName: string, whereKey: string, orderByKey: string, isDescOrder: boolean = false): Query<DocumentData> {
    let queryRef: Query = query(
      this.getCollectionRef(collectionName),
      where(whereKey, '==', true),
      orderBy(orderByKey, isDescOrder ? 'desc' : 'asc')
    );
    return queryRef;
  }

  public get timeoutInterval() {
    return 10000;
  }

  public get getImageBool() {
    return this.isImagesAvailable;
  }
  public get getReviewBool() {
    return this.isReviewsAvailable;
  }
  public get getNewBool() {
    return this.isNewsAvailable;
  }
  public get getVideoBool() {
    return this.isVideosAvailable;
  }
  public get getVisitorCount() {
    return this.visitorCount;
  }

  public onLoad() {
    // this.getSliders()s;
    
  }

  async openPopupModal() {
    let popupData = (await getDoc(doc(this.firestore, `${CONSTANTS.POPUP_COLLECTION}/${CONSTANTS.POPUP_COLLECTION}`))).data();
    let modalRef = this.modalService.open(PopupComponent, {
      animation: false,
      ariaDescribedBy: 'popup',
      ariaLabelledBy: 'popup',
    })
    modalRef.componentInstance.popupModel = popupData;
  }

  async getQuote() {
    let quoteData = (await getDoc(doc(this.firestore, `${CONSTANTS.QUOTE_COLLECTION}/${CONSTANTS.QUOTE_COLLECTION}`))).data();
    this.quoteSubject.next(quoteData);
  }

  getNews() {
    const unsub = onSnapshot(this.getQueryRef(CONSTANTS.NEWS_COLLECTION, 'active', 'addedOn', true), (snapshot) => {
      this.newsSubject.next(snapshot.docs.map(e => ({
        ...e.data(),
        paraSafe: this.sanitizer.bypassSecurityTrustHtml((e.data() as any).newsDescription)
      })));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    });
  }

  async updateVisitorCount() {
    let docRef = doc(this.firestore, 'visitorCount/visitorCount');
    await updateDoc(docRef, { count: increment(1) })
    this.visitorCount = +(await getDoc(docRef)).data()!['count'] ?? 0
  }

  getBrochures() {
    const unsub = onSnapshot(this.getQueryRef(CONSTANTS.BROCHURES_COLLECTION, 'brochureStatus', 'addedOn'), (snapshot) => {
      this.brochuresModelSubject.next(snapshot.docs.map(e => e.data()));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    });
  }

  getSliders() {
    const unsub = onSnapshot(this.getQueryRef(CONSTANTS.SLIDER_COLLECTION, 'sliderStatus', 'addedOn'), (snapshot) => {
      this.slidersSubject.next(snapshot.docs.map(e => e.data()));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  getServices() {
    const unsub = onSnapshot(this.getQueryRef(CONSTANTS.SERVICES_COLLECTION, 'active', 'addedOn'), (snapshot) => {
      this.servicesModelSubject.next(snapshot.docs.map(e => {
        let data: any = { ...e.data() };
        return {
          ...data,
          serviceUrlId: String(data?.title).toLowerCase().replace(/ /g, '-'),
          bannerBgColor: this.backgroundBg[Math.floor(Math.random() * this.backgroundBg.length)]
        }
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  getCourses() {
    const unsub = onSnapshot(this.getQueryRef(CONSTANTS.COURSES_COLLECTION, 'active', 'addedOn'), (snapshot) => {
      this.coursesModelSubject.next(snapshot.docs.map(e => {
        let data: any = { ...e.data() };
        return {
          ...data,
          courseUrlId: String(data.title).toLowerCase().replace(/ /g, '-'),
          bannerBgColor: this.backgroundBg[Math.floor(Math.random() * this.backgroundBg.length)]
        }
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    });
  }

  async getBlogs() {
    const unsub = onSnapshot(this.getCollectionRef(CONSTANTS.BLOGS_COLLECTION), (snapshot) => {
      this.blogsSubject.next(snapshot.docs.map(e => {
        let data: any = { ...e.data() };
        return {
          ...data,
          blogUrlId: String(data.title).toLowerCase().replace(/ /g, '-'),
        }
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    });
  }

  async getAlbumsSubject() {
    let albums = await getDocs(this.getQueryRef(CONSTANTS.ALBUMS_COLLECTION, 'galleryStatus', 'addedOn', true));
    this.albumsSubject.next(albums.docs.map(e => e.data()));
  }

  // Get Gallery Data for Home Page and Sub Page
  getHomeGallery() {
    let queryRef = query(
      this.getQueryRef(CONSTANTS.GALLERY_COLLECTION, 'galleryStatus', 'addedOn', true),
      limit(this.$HOME_DOC_LIMIT)
    );

    const unsub = onSnapshot(queryRef, (snapshot) => {
      this.isImagesAvailable = snapshot.size === this.$HOME_DOC_LIMIT
      this.homeGallerySubject.next(snapshot.docs.map((ele) => {
        this.galleryLastDoc.next(ele);
        return ele.data();
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  getAllGalleryImages() {
    let lastDoc = this.galleryLastDoc.value;
    let queryRef = query(
      this.getQueryRef(CONSTANTS.GALLERY_COLLECTION, 'galleryStatus', 'addedOn', true),
      startAfter(lastDoc)
    );

    const unsub = onSnapshot(queryRef, (snapshot) => {
      this.gallerySubject.next(snapshot.docs.map(e => e.data()));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  // Get Gallery Data for Home Page and Sub Page
  convertVideoUrltoEmbedUrl(videoUrl: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = videoUrl.match(regExp);
    const videoId = match && match[7].length === 11 ? match[7] : "";
    videoUrl = "https://www.youtube.com/embed/" + videoId;
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  getHomeVideos() {
    let queryRef = query(
      this.getQueryRef(CONSTANTS.VIDEOS_COLLECTION, 'active', 'addedOn', true),
      limit(this.$HOME_DOC_LIMIT)
    );

    const unsub = onSnapshot(queryRef, (snapshot) => {
      this.isVideosAvailable = snapshot.size === this.$HOME_DOC_LIMIT
      this.homeVideosSubject.next(snapshot.docs.map((ele) => {
        let vidoModel: any = ele.data();
        vidoModel.videoUrl = this.convertVideoUrltoEmbedUrl(vidoModel.videoUrl);

        this.videosLastDoc.next(ele);
        return vidoModel;
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  getAllVideos() {
    let lastDoc = this.videosLastDoc.value;
    let queryRef = query(
      this.getQueryRef(CONSTANTS.VIDEOS_COLLECTION, 'active', 'addedOn', true),
      startAfter(lastDoc)
    );

    const unsub = onSnapshot(queryRef, (snapshot) => {
      this.videosSubject.next(snapshot.docs.map(e => {
        let vidoModel: any = e.data();
        vidoModel.videoUrl = this.convertVideoUrltoEmbedUrl(vidoModel.videoUrl);
        return vidoModel;
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  // Get Student Reviews Data for Home Page and Sub Page
  getHomeReviews() {
    let queryRef = query(
      this.getQueryRef(CONSTANTS.REVIEWS_COLLECTION, 'reviewStatus', 'addedOn', true),
      limit(this.$HOME_DOC_LIMIT)
    );

    const unsub = onSnapshot(queryRef, (snapshot) => {
      this.isReviewsAvailable = snapshot.size === this.$HOME_DOC_LIMIT
      this.homeReviewSubject.next(snapshot.docs.map((ele) => {
        this.reviewLastDoc.next(ele);
        return ele.data();
      }));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }

  getAllRemainingReviews() {
    let lastDoc = this.reviewLastDoc.value;
    let queryRef = query(
      this.getQueryRef(CONSTANTS.REVIEWS_COLLECTION, 'reviewStatus', 'addedOn', true),
      startAfter(lastDoc)
    );

    const unsub = onSnapshot(queryRef, (snapshot) => {
      this.reviewSubject.next(snapshot.docs.map(e => e.data()));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    })
  }


  // Get Exam Topic (CueCard | WrtitngTask2)
  async getTopics(isCueCardTopicBool: boolean) {
    let courses = await getDocs(query(
      this.getCollectionRef(CONSTANTS.TOPICS_COLLECTION),
      where("isCueCardTopic", '==', isCueCardTopicBool),
      orderBy('examDate', 'desc')
    ));
    this.topicsModelListSubject.next(courses.docs.map(e => {
      let data: any = { ...e.data() };
      return {
        ...data
      }
    }));
  }

  getBranches() {
    let unsub = onSnapshot(this.getQueryRef(CONSTANTS.CONTACT_COLLECTION, 'contactStatus', 'addedOn'), (snapshot) => {
      let list = snapshot.docs.map(e => {
        let data = { ...e.data() as ContactModel };
        return {
          ...data,
          branchUrlId: data.title?.toLowerCase().replace(/ /g, '-')
        }
      });
      this.branchesModelSubject.next(list.filter(x => !x.mainBranch));
      this.mainBranchModelSub.next(list.find(x => x.mainBranch));
      this.getWindowRef().setTimeout(() => unsub(), this.timeoutInterval * 6);
    }, (error) => {
      console.log(error);
    });
  }

  async getFAQs() {
    let faq = await getDocs(this.getQueryRef(CONSTANTS.FAQ_COLLECTION, 'active', 'addedOn', true));
    this.faqModelListSubject.next(faq.docs.map(e => e.data()));
  }

  async getCarrers() {
    let faq = await getDocs(this.getQueryRef(CONSTANTS.CAREERS_COLLECTION, 'active', 'addedOn', true));
    this.careersModelListSubject.next(faq.docs.map(e => e.data()));
  }

  async getSocialUrl() {
    let docRef = await getDoc(doc(this.getCollectionRef(CONSTANTS.SOCIAL_COLLECTION), CONSTANTS.SOCIAL_COLLECTION));
    this.socialSubject.next(docRef.data());
  }

}
