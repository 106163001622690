import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ContactModel } from 'src/app/modals/ContactModel';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  @ViewChild('mapLinkIframe', { static: true, read: ElementRef }) branchMapLinkChildRef!: ElementRef<HTMLIFrameElement>;

  branchModel: ContactModel | any;
  branchMapLink!: SafeResourceUrl;
  branchUrlId?: string;

  isIframeLoadedBool: boolean = true;
  
  constructor(
    private route: ActivatedRoute,
    private dbService: DbService
  ) {
    this.isIframeLoadedBool = true
    this.route.params.subscribe(({ branchId }) => {
      this.branchUrlId = branchId ?? null;
      this.getData();
    })
  }

  ngOnInit(): void {
  }

  isIframeLoaded() {
    this.isIframeLoadedBool = false
  }

  getData() {
    let sub = this.dbService.branchesModelSubject.subscribe((list) => {
      if(list !== null) {
        this.isIframeLoadedBool = true
        this.branchModel = list.find((x: ContactModel) => x['branchUrlId'] === this.branchUrlId);
        this.branchMapLink = this.dbService.sanitizer.bypassSecurityTrustResourceUrl(this.branchModel?.mapLink);
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval);
      }
    })
  }
}
