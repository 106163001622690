import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  reviewsList: any[] = [];

  constructor(
    private dbService: DbService
  ) { }

  ngOnInit(): void {
    this.getInitialData();
  }

  getInitialData() {
    let sub = this.dbService.homeReviewSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.reviewsList = [...value];
        this.getRemainingData();
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6) // 60 seconds
      }
    })
  }

  getRemainingData() {
    this.dbService.getAllRemainingReviews();
    let sub = this.dbService.reviewSubject.subscribe((value) => {
      if(value.length !== 0) {
        this.reviewsList = this.reviewsList.concat(value);
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval * 6)
      }
    })
  }
}
