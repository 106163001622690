<div class="container-fluid">
    <div class="row">
      <div class="col p-0">
        <div style="
          background: url('./../../../assets/heads/contact-us1.webp') no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 250px;
        ">
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="row">
      <div class="col">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <h3 class="m-0 mb-2" style="font-family: serif;">Contact Us</h3>
          <span>Have an Query? Feel Free to contact us</span>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 col-sm-6">
        <div class="card">
          <form [formGroup]="queryForm" (ngSubmit)="onSubmit($event);">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="name">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name">
              </div>
              <div class="mb-3">
                <label class="form-label" for="phone">Phone</label>
                <input type="tel" class="form-control" id="phone" formControlName="phone">
              </div>
              <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input type="email" class="form-control" id="email" formControlName="email">
              </div>
              <div class="mb-3">
                <label class="form-label" for="query">Query</label>
                <textarea name="query" id="query" rows="5" formControlName="query" class="form-control"></textarea>
              </div>
  
              <div class="alert alert-success" role="alert" *ngIf="formSubmitted">
                <!-- *ngIf="formSubmitted" -->
                <h4 class="alert-heading">Form Submitted!</h4>
                <p>We have received your query. Will get back to you soon.</p>
              </div>
            </div>
            <div class="card-footer bg-white border-0">
              <div class="col px-0">
                <button type="submit" class="btn btn-lg custom-btn w-100" aria-label="submit1" [disabled]="formSubmitted">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-sm-6 mt-3 mt-sm-0">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column">
              <div class="d-flex text-muted my-2" style="gap: 10px;">
                <i class="bi bi-house fa-lg"></i>
                <span>{{ mainBranch!.address || "" }}</span>
              </div>
              <div class="d-flex text-muted my-2" style="gap: 10px;">
                <i class="bi bi-envelope fa-lg"></i>
                <a [href]="'mailto:'+mainBranch!.email || ''" aria-label="mail">
                  {{ mainBranch!.email || "" }}
                </a>
              </div>
              <div class="d-flex text-muted my-2 mb-3" style="gap: 10px;">
                <i class="bi bi-telephone fa-lg"></i>
                <a [href]="'tel:+91'+mainBranch!.phone" aria-label="phone">{{ mainBranch!.phone || "" }}</a>
              </div>
              <iframe [src]="mapLink" allowfullscreen="" height="352" frameBorder="0" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade" style="width: 100%;"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="row mt-2">
      <div class="col-12 mb-4" *ngFor="let contact of contactList; index as idx; odd as odd; even as even">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6" [ngClass]="{
                'order-md-1': odd,
                'order-md-0': even
              }">
              <h4>{{ contact.title }}</h4>
                <div class="d-flex text-muted my-2" style="gap: 10px;">
                  <i class="bi bi-house fa-lg"></i>
                  <span>{{ contact?.address }}</span>
                </div>
                <div class="d-flex text-muted my-2" style="gap: 10px;">
                  <i class="bi bi-envelope fa-lg"></i>
                  <a [href]="'mailto:'+contact?.email" aria-label="mail1">
                    {{ contact?.email || "" }}
                  </a>
                </div>
                <div class="d-flex text-muted my-2 mb-3" style="gap: 10px;">
                  <i class="bi bi-telephone fa-lg"></i>
                  <a [href]="'tel:+91'+contact?.phone" aria-label="phone1">{{ contact?.phone || "" }}</a>
                </div>
              </div>
              <div class="col-12 col-sm-6" [ngClass]="{
                'order-md-0': odd,
                'order-md-1': even
              }">
                <iframe [src]="contact?.urlSafe || ''" allowfullscreen="" frameBorder="0" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade" height="250" style="width: 100%;"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>