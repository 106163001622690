import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqList: any[] = [];

  constructor(
    private dbService: DbService
  ) {}

  ngOnInit(): void {
    this.dbService.getFAQs();
    let sub = this.dbService.faqModelListSubject.subscribe((list) => {
      if(list.length !== 0) {
        this.faqList = [...list];
        this.dbService.getWindowRef().setTimeout(() => sub.unsubscribe(), this.dbService.timeoutInterval)
      }
    })
  }

}
