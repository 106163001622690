<div class="container-fluid">
    <div class="row">
      <div class="col p-0">
        <div style="
          background: url('./../../../assets/heads/events1.webp') no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 250px;
        ">
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="row ">
      <div class="col">
        <h5 class="display-4 heading-font text-center heading-font">Events</h5>
      </div>
    </div>
    <!-- <hr class="my-3"> -->
    <div class="row">
      <div class="col-12 col-sm-4 mb-4" *ngFor="let gallery of albums; index as idx">
        <div class="card">
          <div class="card-body bg-none">
            <div class="row">
              <div class="col">
                <img loading="lazy" [src]="gallery.imageUrl" alt="gallery3" class="card-img-top rounded" loading="lazy">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <h5 class="text-wrap">{{ gallery.title | titlecase }}</h5>
                <p class="text-muted paraLimit">{{ gallery.description }}</p>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <button type="button" class="btn custom-outline-btn btn-sm float-right" aria-label="read1" *ngIf="branchId === null" [routerLink]="['/events', gallery.albumId]">Read More</button>
                <button type="button" class="btn custom-outline-btn btn-sm float-right" aria-label="read2" *ngIf="branchId !== null" [routerLink]="['/events', gallery.albumId]" [queryParams]="{ branchId: branchId }">Read More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>