import { Component } from '@angular/core';
import { Firestore, DocumentData, Query, collection, orderBy, getDocs, query } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ALBUMS_COLLECTION, ALBUMS_MEDIA_COLLECTION, CONTACT_COLLECTION } from 'src/app/constants';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent {
  branchId: string | null = null;
  mediaList: any[] = [];
  albumInfo: any;

  showLoader: boolean = false;

  social: any = {};

  constructor(
    public dbService: DbService,
    private firestore: Firestore,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => this.branchId = res['branchId'] ?? null);
    this.route.params.subscribe((value) => {
      this.getAlbumInfo(value['albumId']);
      this.getMedia(value['albumId']);
    })
  }

  stopLoading(idx: number) {
    this.mediaList[idx].showLoader = false;
  }

  getVideoLink(videoUrl: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  getAlbumInfo(albumId: any) {
    this.dbService.albumsSubject.subscribe((list) => {
      if(list !== null) {
        this.albumInfo = { ...list.find((x): any => x.albumId === albumId) }
      }
    })
  }

  getMedia(albumId: any) {
    let collectionRef: Query<DocumentData>;
    if(this.branchId === null) {
      collectionRef = collection(this.firestore, `${ALBUMS_COLLECTION}/${albumId}/${ALBUMS_MEDIA_COLLECTION}`)
    } else {
      collectionRef = collection(this.firestore, `${CONTACT_COLLECTION}/${this.branchId}/${ALBUMS_COLLECTION}/${albumId}/${ALBUMS_MEDIA_COLLECTION}`)
    }
    
    getDocs(query(collectionRef, orderBy('createdOn', 'desc')))
      .then((value) => {
        this.mediaList = value.docs.map(e => {
          let mediaModel: any = { ...e.data() }
          return {
            ...mediaModel,
            showLoader: true,
            urlSafe: mediaModel.videoUrl !== '' ? this.sanitizer.bypassSecurityTrustResourceUrl(mediaModel.videoUrl) : ''
          }
        });
      }, (error) => console.log(error))
  }
}
