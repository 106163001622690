import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbAccordionModule, NgbCarouselModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { HomeComponent } from './pages/home/home.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ComponentsModule } from './components/components.module';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { CalculateIELTSComponent } from './pages/calculate-ielts/calculate-ielts.component';
import { EventsComponent } from './pages/events/events.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { ServicesComponent } from './pages/service/service.component';
import { BranchComponent } from './pages/branch/branch.component';
import { DbService } from './services/db.service';
import { JobVacanciesComponent } from './pages/job-vacancies/job-vacancies.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopicsComponent } from './pages/topics/topics.component';
import { AlbumsComponent } from './pages/albums/albums.component';
import { SubblogsComponent } from './pages/subblogs/subblogs.component';
import { LoaderComponent } from './pages/loader/loader.component';
import { ImagesComponent } from './pages/images/images.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { VideosComponent } from './pages/videos/videos.component';
import { NewsComponent } from './pages/news/news.component';

/**
 export let FIREBASE_APP: FirebaseApp;
 * initializeFirestore(FIREBASE_APP, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
      ignoreUndefinedProperties: false
    })
 */

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    ContactusComponent,
    AboutusComponent,
    CalculateIELTSComponent,
    EventsComponent,
    BlogsComponent,
    CoursesComponent,
    ServicesComponent,
    BranchComponent,
    JobVacanciesComponent,
    TopicsComponent,
    AlbumsComponent,
    SubblogsComponent,
    LoaderComponent,
    ImagesComponent,
    ReviewsComponent,
    VideosComponent,
    NewsComponent
  ],
  imports: [
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    ToastNoAnimationModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      tapToDismiss: true,
      preventDuplicates: true,
      closeButton: true
    }),
    FormsModule,
    ReactiveFormsModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCarouselModule,
    ComponentsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
  ],
  providers: [
    DbService,
    // { 
    //   provide: APP_INITIALIZER,
    //   useFactory: function(dbService: DbService) {
    //     return () => dbService.onLoad();
    //   },
    //   deps: [DbService],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
